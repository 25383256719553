import { Component, OnInit, Input, Output , Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/users.service';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { HttpClient } from '@angular/common/http';  
import { DomSanitizer } from '@angular/platform-browser';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-project-info-files',
  templateUrl: './project-info-files.component.html',
  styleUrls: ['./project-info-files.component.scss']
})
export class ProjectInfoFilesComponent implements OnInit {
	@Input() public id;
	@Input() public organisationId;
	@Input() public roleKey;
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

  files: File[] = [];
	item;
	errors = {};
	successmessage;
	errormessage;
	changepassword;
  projectFiles;

  clientDDVisible = false;
  clientDDKeyword = '';

  contactDDVisible = false;
  contactDDKeyword = '';

  managerDDVisible = false;
  managerDDKeyword = '';

  locationDDVisible = false;
  locationDDKeyword = '';
  
  locationKeyword = '';

	organisations : any = [];
	clientContacts : any = [];
	users : any = [];
  addresses : any = [];
  loading = false;
  loaderOverlay = true;
	
	constructor(
		public service: UsersService,
    public apiService: ApiConnectionService,
		public renderer: Renderer2,
		public modalService: NgbModal,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    public app: AppService,
		public activeModal: NgbActiveModal
	) { 
    this.renderer.listen('window', 'click', (evt) => {
      if (evt.target.closest('.auto-dropdown') == null && !evt.target.classList.contains('dd-input')) {
        this.clientDDVisible = false;
        this.contactDDVisible = false;
        this.managerDDVisible = false;
        this.locationDDVisible = false;
      }
    })
  }

	dismiss() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

  continue () {
    let self = this;
    this.app.setLoaderStatus(true);
    setTimeout(function () {
      self.activeModal.close(false);
      self.app.setLoaderStatus(false);
    },1000);
  }

	cancel() {
		this.activeModal.close(false);
	}

	ngOnInit(): void {
    let self = this;
    self.app.setLoaderStatus(true);
    setTimeout (function () {
      self.fetchItem();
      self.getFiles();
      self.app.setLoaderStatus(false);
    },1000);
	}

	ngOnChanges(changes: any) {
		this.fetchItem();
    this.getFiles();
	}
	
	fetchItem () {	
		let self = this;
    self.item = {};
		this.service.read('projects/details/'+self.id+'').then((response) => {		
			self.item = response;
      self.loaderOverlay = false;
		});
	}
	
	getFiles () {	
		let self = this;
    self.projectFiles = [];
		this.service.read('projects/details/'+self.id+'/files').then((response) => {		
			self.projectFiles = response['items'];
		});
	}
  
  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
 }

  onSelect(event) {
    let self = this;
    this.loaderOverlay = true;
    this.files.push(...event.addedFiles);  

    const formData = new FormData();  
  
    for (var i = 0; i < this.files.length; i++) {   
      formData.append("file[]", this.files[i]);  
    }  
    
    self.app.setLoaderStatus(true);
    this.apiService.saveFile('projectFiles/upload/'+this.id+'', formData).then(function (response) {
      self.files = [];
      self.loaderOverlay = false;

      self.app.setLoaderStatus(false);
      self.app.setSystemMessageText('Bestand opgeslagen');
      self.app.setSystemMessageType('SUCCESS');
      self.app.setSystemMessageLoading(true);
      
      self.getFiles();
    });
  }
  
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
  

	delete (object) {
	  let self = this;
	  this.apiService.delete('projectFiles/delete/'+object.id+'').then((response) => {
      self.getFiles();
	  }).catch (function (error) {
	  });	
	}
	
	confirmDelete(object) {
		let self = this;
		
	  const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	  deleteModal.componentInstance.message = 'Weet je zeker dat je '+object.name+' wilt verwijderen?';
	  deleteModal.result.then((result) => {
		  if (result) {
			  this.delete(object);
		  }
	  });
	}
onFilesAdded(event) {
  this.files.push(...event.addedFiles);

  this.readFile(this.files[0]).then(fileContents => {

  });
}

private async readFile(file: File): Promise<string | ArrayBuffer> {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      return resolve((e.target as FileReader).result);
    };

    reader.onerror = e => {
      console.error(`FileReader failed on file ${file.name}.`);
      return reject(null);
    };

    if (!file) {
      console.error('No file to read.');
      return reject(null);
    }

    reader.readAsDataURL(file);
  });
}

}
