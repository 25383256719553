import { Component, OnInit , Input , Output} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-quote-accepted-form',
  templateUrl: './quote-accepted-form.component.html',
  styleUrls: ['./quote-accepted-form.component.scss']
})
export class QuoteAcceptedFormComponent implements OnInit {
  
	@Input() public item;

  constructor(
    public activeModal: NgbActiveModal,
    public api: ApiConnectionService
  ) { }

  ngOnInit(): void {
  }

  save (value)
  {
    let self = this;
    this.api.read('projects/changeQuoteAccepted/'+self.item['id']+'/'+value+'').then(function (response) {
      self.activeModal.close(true);
    });
  }

	close () {
		this.activeModal.close(false);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	dismiss () {
		this.activeModal.close(false);
	}
}
