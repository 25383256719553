<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h5 class="modal-title">Borden toevoegen</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body"> 
		<ul class="nav nav-fill mb-4">
			<li class="nav-item">
				<span class="nav-link " (click)="changeTab('all')" [ngClass]="{'active' : tabs['all'] == true}">Alles</span>
			</li>
			<li class="nav-item">
				<span class="nav-link " (click)="changeTab('quote')" [ngClass]="{'active' : tabs['quote'] == true}">Uit offerte</span>
			</li>
			<li class="nav-item">
				<span class="nav-link " (click)="changeTab('phase')" [ngClass]="{'active' : tabs['phase'] == true}">Uit fase</span>
			</li>
		</ul>
        <div class="" [ngClass]="{'d-none' : tabs['all'] != true }">
            <app-project-add-sign-list (sendActiveSignIds)="setSignIds($event)" [phase]="phase" [project]="project" [type]="'all'"></app-project-add-sign-list>
        </div>
        <div class="" [ngClass]="{'d-none' : tabs['quote'] != true }">
            <app-project-add-sign-list (sendActiveSignIds)="setSignIds($event)" [phase]="phase" [project]="project" [type]="'quote'"></app-project-add-sign-list>
        </div>
        <div class="" [ngClass]="{'d-none' : tabs['phase'] != true }">
            <app-project-add-sign-list (sendActiveSignIds)="setSignIds($event)" [phase]="phase" [project]="project" [type]="'phase'"></app-project-add-sign-list>
        </div>

	    <input type="text" style="opacity:0;">
	</div>
	<div class="modal-footer">
	    <button (click)="cancel()" type="submit" class="btn btn btn-link" style="text-decoration:none ! important;color:#000000;">Annuleren</button>
	    <button (click)="confirm()" type="submit" class="btn btn-success">Toevoegen</button>
	</div>
</section>