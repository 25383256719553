<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">Verstuur offerte P{{project['number']}}</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1 pe-3" *ngIf="data">
        <div class="py-5 text-center" *ngIf="showSuccess == true">
            <p>Offerte is succesvol verstuurd.</p>
        </div>

        <div *ngIf="showSuccess == false">
            <div class="row mt-2 mb-1">
                <div class="col-2 pt-2 text-end">
                    <label for="offerte" class="main-label">Van</label>
                </div>
                <div class="col-10">
                    <input type="text" readonly class="form-control" value="{{project['authorEmail']}}">
                </div>
            </div> 
            <div class="row mb-1">
                <div class="col-2 pt-2 text-end">
                    <label for="offerte" class="main-label">Aan</label>
                </div>
                <div class="col-10">
                    <select class="form-control" [(ngModel)]="data['contactId']">
                        <option value="{{contact['id']}}" *ngFor="let contact of data['contacts']">
                            {{contact['name']}} - {{contact['email']}}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row mb-1">
                <div class="col-2 pt-2 text-end">
                    <label for="offerte" class="main-label">CC</label>
                </div>
                <div class="col-4">
                    <input type="text" [(ngModel)]="data['cc']" class="form-control" value="">
                </div>
                <div class="col-2 pt-2 text-end">
                    <label for="offerte" class="main-label">BCC</label>
                </div>
                <div class="col-4">
                    <input type="text" [(ngModel)]="data['bcc']" class="form-control" value="">
                </div>
            </div> 
            <div class="row mb-1">
                <div class="col-2 pt-2  text-end">
                    <label for="offerte" class="main-label">Offerte</label>
                </div>
                <div class="col-4">
                    <select class="form-control" [(ngModel)]="data['fileId']">
                        <option value="{{file['id']}}" *ngFor="let file of data['files']">
                            {{file['name']}} - {{file['createdAtText']}}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row mb-1">
                <div class="col-2 pt-2 text-end">
                    <label for="offerte" class="main-label">Onderwerp</label>
                </div>
                <div class="col-10">
                    <input type="text" class="form-control" [(ngModel)]="data['subject']">
                </div>
            </div> 
            <div class="row mt-2 mb-1">
                <div class="col-2 pt-2 text-end">
                    <label for="offerte" class="main-label">Bericht</label>
                </div>
                <div class="col-10">
                    <textarea class="form-control" style="height:300px;" [(ngModel)]="data['body']"></textarea>
                </div>
            </div> 
        </div>
    </div>
    <div class="modal-footer text-end" *ngIf="showSuccess == false">
        <a class="btn btn-success with-icon" (click)="send()">Verstuur <i class="bi bi-arrow-right"></i></a>
    </div>
</div>