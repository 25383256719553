import { Injectable } from '@angular/core';
import { ApiConnectionService }  from '../services/core/api-connection.service';
import { ConfigService }  from '../services/core/config.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../services/core/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends ApiConnectionService {
	constructor(public http: HttpClient, public configService: ConfigService, 
		public authenticationService: AuthenticationService, public router: Router) { 
		super(http, configService, authenticationService, router);
	}
  	
	public dataSource = new BehaviorSubject({});
	public data = this.dataSource.asObservable();
	
	getUnreadCount () {
		let self = this;
		this.read('lessonFeedbacks/unread').then((response) => {
			self.dataSource.next(response['count']);
		});
	}
}
