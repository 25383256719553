import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss']
})
export class SetupPasswordComponent implements OnInit {

	loading = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}