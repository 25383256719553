import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';


@Component({
  selector: 'app-week-form',
  templateUrl: './week-form.component.html',
  styleUrls: ['./week-form.component.scss']
})
export class WeekFormComponent implements OnInit {
	@Input() public title;
	@Input() public targetUrl;
	@Input() public button;
	@Input() public message;
	@Input() public year;
	@Input() public week;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay;
	public years = [
	];

	public weeks = [];

	constructor(
  		public activeModal: NgbActiveModal,
  		public app: AppService,
		public api: ApiConnectionService
  	) { 
		for (let i=1;i<=53;i++)
		{
			let str = i.toString();
			if (i < 10)
			{
				str = '0' + i;
			}
			this.weeks.push(str);
		}
		var curr = new Date;
		let maxYear = curr.getFullYear();
			maxYear++;
		for (let i=maxYear;i>2021;i--)
		{
			let str = i.toString();
			this.years.push(str);
		}
	}

	ngOnInit(): void {
		let currentDate = new Date;
		let startDate = new Date(currentDate.getFullYear(), 0, 1);
	
		let tmp1 = 0;
		let tmp2 = 0;
		 tmp1 = currentDate.getTime();
		 tmp2 = startDate.getTime(); 
	
		var days = Math.floor((tmp1 - tmp2) /
			(24 * 60 * 60 * 1000));
			 
		var weekNumber = Math.ceil(days / 7); 		
		this.week = weekNumber;
		this.year = currentDate.getFullYear();
	}
  
	confirm () {
		let self = this;

		self.loaderOverlay = true;
		self.app.setLoaderStatus(true);

		this.api.read(self.targetUrl+'/'+self.week+'/'+self.year+'').then(function (response) {
			self.loaderOverlay = false;
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText('Week toegevoegd');
			self.app.setSystemMessageType('SUCCESS');
			self.activeModal.close(response);
		}).catch(function (response) {
			self.loaderOverlay = false;
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText(response['error']['message']);
			self.app.setSystemMessageType('DANGER');
		});
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}

	dateString (week)
	{
		var curr = new Date; // get current date
		
		let y = this.year;
		var d = new Date("Jan 01, " + y + " 01:00:00");
		var dayMs = (24 * 60 * 60 * 1000);
		var offSetTimeStart = dayMs * (d.getDay() - 1);
		var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
		var n1 = new Date(w);
		var n2 = new Date(w + 518400000);
		
		let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
		let month = n1.getMonth();
			month = (month + 1);
		let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

		var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
	  	return firstday;
	}
}