import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-project-calculation-items',
  templateUrl: './project-calculation-items.component.html',
  styleUrls: ['./project-calculation-items.component.scss']
})
export class ProjectCalculationItemsComponent implements OnInit {
	@Input() public project;
  @Input() public type : string = 'QUOTE';
  categories = [];
  items : any = [];
  itemUnits;
  activeRowNameId = '';
  activeRowTypeId = '';
  activeRowTypeTimeout;
  activeRowNameTimeout;

  public suggestions : any = [];

  constructor(
    private app: AppService,
    private modalService: NgbModal,
    private config: ConfigService,
    private api: ApiConnectionService
  ) { }

  ngOnInit(): void {
    this.categories = this.config.get('project_calculation_item_category_options');
    this.fetchItems();
    this.itemUnits = this.config.get("project_calculation_item_unit_options");
  }
  
  ngOnChanges(changes: any) {
    this.fetchItems();
    this.itemUnits = this.config.get("project_calculation_item_unit_options");
  }

  fetchItems ()
  {
    if (!this.type)
    {
      return;
    } 
    let self = this;
    this.api.read('calculationItems/project/'+ this.project['id'] +'/'+self.type.toLocaleLowerCase()+'').then(function (response) {
      self.items = response;
      self.getSuggestions();
    });
  }

  update (item)
  {
    let self = this;
    this.api.save('calculationItems/update/'+item['id']+'', item).then(function (response) {
      self.items = response;
    });
  }

  addRow (category)
  {
    let self = this;
    this.api.save('calculationItems/add', {
      projectId: self.project['id'],
      type: self.type,
      category: category
    }).then(function (response) {
      self.items = response;
    });
  }

  confirmDelete(item) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message =
      "Weet je zeker dat je dit element wilt verwijderen?";
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(item);
      }
    });
  }

  delete(item) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .delete("calculationItems/delete/" + item.id + "")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Regel verwijderd");
        self.app.setSystemMessageType("DANGER");
        self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  selectAll(event) {
    return true;
    if (event.target.value === "0.00") {
      event.target.select();
    }
  }

  showUnitList (event, row) { 
    this.activeRowTypeId = row['id'];
    this.selectAll(event);
    clearTimeout(this.activeRowTypeTimeout);
  }

  selectUnit (row, searchUnit) 
  {
    row['unit'] = searchUnit;
    this.activeRowTypeId = '';
    this.update(row);
  }

  hideUnitList (event, row) {
    let self = this;
    self.activeRowTypeTimeout = setTimeout(function () {
      self.activeRowTypeId = '';
    }, 500);
  }

  searchUnits (row)
  {
    let list = [];
    for (let i=0;i<this.itemUnits.length;i++)
    {
      let item = this.itemUnits[i];
      let string = "" + this.itemUnits[i].toLowerCase() + "";
      let result = string.includes(row['unit'].toLowerCase());

      if (row['unit'] == "" || result) {
        list.push(item);
      }
    }
    return list;
  }

  getSuggestions () {
    let self = this;
    this.api
      .read("calculationItems/suggestions")
      .then(function (response) {
        self.suggestions = response["items"];
      });
  }

  hideNameList (event, row) {
    let self = this;
    self.activeRowNameTimeout = setTimeout(function () {
      self.activeRowNameId = '';
    }, 500);
  }

  showNameList (event, row) {
    clearTimeout(this.activeRowNameTimeout);
    this.activeRowNameId = row['id'];
  }

  selectName (row, searchName) 
  {
    row['description'] = searchName['name'];
    row['price'] = searchName['sellPrice'];
    // row['unit'] = searchName['unit'];

    this.activeRowNameId = '';
    this.update(row);
  }

  searchNames (row)
  {
    let list = [];
    for (let i=0;i<this.suggestions.length;i++)
    {
      let item = this.suggestions[i];
      let string = "" + this.suggestions[i]['name'].toLowerCase() + "";
      let result = string.includes(row['description'].toLowerCase());
      if (row['description'] == "" || row['description'].length < 0) { 
        list.push(item);
      } else if (result) {
        list.push(item);
      }
    }
    return list;
  }

  convertQuote () 
  {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .read("calculationItems/convertQuote/" + self.project.id + "")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Kosten succesvol toegevoegd");
        self.app.setSystemMessageType("SUCCESS");
        self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });    
  }
}