import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-project-add-sign-list',
  templateUrl: './project-add-sign-list.component.html',
  styleUrls: ['./project-add-sign-list.component.scss']
})
export class ProjectAddSignListComponent implements OnInit {

	@Output() sendActiveSignIds = new EventEmitter<any>();
	@Input() project;
	@Input() phase;
	@Input() type = 'all';

	signs = [];
	selectedSigns = {};
	activeSignIds = {};
	keyword = ''; 
	selectAllSelected = false;

	constructor(
    public activeModal: NgbActiveModal,
    public app: AppService,
    public api: ApiConnectionService
  ) { 

  }

	ngOnInit(): void {
		this.all();
	}
  
	ngOnChanges(changes: any) {
	  this.all();
	}

	selectAll ()
	{
		let signs = this.searchSigns();
		for (let index = 0; index < signs.length; ++index) {
		  let contact = signs[index];
		  this.selectedSigns[contact['id']] = contact['id'];
		}
		this.selectAllSelected = true;
	}

	deSelectAll ()
	{
		let signs = this.searchSigns();
		for (let index = 0; index < signs.length; ++index) {
		  	let contact = signs[index];
		  	if (this.selectedSigns.hasOwnProperty(contact['id'])) {
				delete this.selectedSigns[contact['id']];
			}
		}
		this.selectAllSelected = false;
	}

	changeQuantity (sign, quantity) 
	{
		sign['quantity'] = quantity;
		this.handleActiveSignIds();
	}

	searchSigns ()
	{
		let list = [];
		for (let index = 0; index < this.signs.length; ++index) {
		  let contact = this.signs[index];
		  let string = ''+contact['name'].toLowerCase()+'';
		  let result = string.includes(this.keyword.toLowerCase()); 
		  if (this.keyword == '' || result)
		  {
			contact['hide'] = false;
		  }
		  else
		  {
			contact['hide'] = true;
		  }
		  this.signs[index] = contact;
		}

		return list;
	}

	all ()
	{
	  let self = this;  
	  this.api.read("inventory/"+self.type+"/"+self.project.id+"/"+self['phase']['id']+"").then((response) => {
		  self.signs = response['items'];
	  });
	}

	handleActiveSignIds ()
	{
		let list = [];
		for (let index = 0; index < this.signs.length; ++index) {
			let item = this.signs[index];
			if (item['quantity'] > 0)
			{
				list.push({
					id: item['id'],
					quantity: item['quantity']
				});
			}
		}
    	this.sendActiveSignIds.emit({
			items: list,
			type: this.type
		});
	}
}
