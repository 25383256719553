import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-filter',
  templateUrl: './edit-filter.component.html',
  styleUrls: ['./edit-filter.component.scss']
})
export class EditFilterComponent implements OnInit {
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Input() public activeFilterKey;
	@Input() public filters;
	@Input() public filterOptions;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  activeFilterOption = null;
  activeFilter = null;
  keyword = '';
  startDate = '';
  endDate = '';
  selectedItems = {};

	constructor(
  		public activeModal: NgbActiveModal
  	) { 
      
    }

	ngOnInit(): void {
    this.setActiveFilterOption();
    this.setActiveFilter();
	}
  
  ngOnChanges(changes: any) {
    this.setActiveFilterOption();
    this.setActiveFilter();
  }

  setActiveFilter ()
  {
    let self = this;
    if (!self.filters)
    {
      return null;
    }
    for (let i=0;i<self.filters.length;i++)
    {
      if (self.filters[i].key === self.activeFilterKey)
      {
        self.activeFilter = self.filters[i];
        if (self.activeFilterOption['type'] === 'STRING')
        {
          self.keyword = self.activeFilter.valueText;
        }
        let tmp = self.activeFilterOption.options;
        let selectedKeys = [];
        tmp.forEach(function (item, index) {
          selectedKeys.push(item['key']);
        });
        if (self.activeFilterOption['type'] === 'SELECT')
        {
          let self = this;
          this.selectedItems = {};
          let array = self.activeFilter.values;
          array.forEach(function (item, index) {
            if (selectedKeys.includes(item)) {
              self.selectedItems[item] = true;
            }
          });
        }
        if (self.activeFilterOption['type'] === 'DATERANGE')
        {
          this.startDate = self.activeFilter.values[0];
          this.endDate = self.activeFilter.values[1];
        }
        return;
      }
    }

    self.activeFilter = null;
  }

  toggleList (key)
  {
    if (this.selectedItems[key] == true) {
      delete this.selectedItems[key];
    } else {
      this.selectedItems[key] = true;
    }
      
  }

  setActiveFilterOption ()
  {
    let self = this;
    if (!self.filterOptions)
    {
      return null;
    }
    
    for (let i=0;i<self.filterOptions.length;i++)
    {
      if (self.filterOptions[i].key === self.activeFilterKey)
      {
        self.activeFilterOption = self.filterOptions[i];
        return;
      }
    }
    self.activeFilterOption = null;
  }

	editFilter(key) {
		this.activeModal.close(key);
	}
  
	close () {
		this.activeModal.close(false);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	dismiss () {
		this.activeModal.close(false);
	}
  
  
	confirm () {
    let values
    if (this.activeFilterOption['type'] === 'STRING')
    {
      values = [this.keyword];
    }
    if (this.activeFilterOption['type'] === 'SELECT')
    {
      values = Object.getOwnPropertyNames(this.selectedItems);
    }
    if (this.activeFilterOption['type'] === 'DATERANGE')
    {
      values = [
        this.startDate, this.endDate
      ];
    }
		this.activeModal.close({
      key: this.activeFilterOption['key'],
      values: values
    });
	}
  
	remove (key) {
		this.activeModal.close({
      action: 'remove',
      key: key
    });
	}
}