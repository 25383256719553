import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-project-phase-mutate',
  templateUrl: './project-phase-mutate.component.html',
  styleUrls: ['./project-phase-mutate.component.scss']
})
export class ProjectPhaseMutateComponent implements OnInit {
  @Input() public project;
  @Input() public phase;
  loaderOverlay

	constructor(
    public activeModal: NgbActiveModal,
    public api: ApiConnectionService
  ) { }

  ngOnInit(): void {

  }

  confirm () {
    let self = this;
    this.loaderOverlay = true;
    this.api.save('phases/save', self.phase).then(function () {
      self.loaderOverlay = false;
      self.activeModal.close(false);
    });
  }

  dismiss () {
    this.activeModal.close(false);
  }

  close () {
    this.activeModal.close(false);
  }

  cancel () {
    this.activeModal.close(false);
  }
}
