<section class="phases-list">
    <div class="phase-list-header">
        <div class="phase-list-collapse">
        </div>
        <div class="phase-list-col-1">
            <div class="phase-sign-name">
                Project
            </div>
            <div class="phase-list-date d-none d-lg-block">
                Datum
            </div>
        </div>
        <div class="phase-sign-status d-none d-lg-block">
            Bord
        </div>
        <div class="phase-sign-action">
        </div>
    </div>
    <section class="phase-item-container" *ngFor="let phase of items">
        <div class="phase-list-row header">
            <div class="phase-list-collapse pt-4">
                <span class="px-1" style="cursor: pointer;" (click)="changeCollapse(phase['phase']['id'], true)"
                    [ngClass]="{
                    'd-none': collapse[phase['phase']['id']] == true
                }">
                    <i class="bi bi-chevron-down"></i>
                </span>
                <span class="px-1" style="cursor: pointer;" (click)="changeCollapse(phase['phase']['id'], false)"
                    [ngClass]="{
                    'd-none': collapse[phase['phase']['id']] != true
                }">
                    <i class="bi bi-chevron-up"></i>
                </span>
            </div>
            <div class="phase-list-col-1">
                <div class="phase-sign-name">
                    {{phase['project']['title']}} <span class=""
                        style="font-weight:normal;">({{phase['phase']['activeState']}}</span><br />
                    <span style="font-weight:normal">Fase {{phase['phase']['number']}}</span>
                </div>
                <div class="phase-list-date d-none d-lg-block">
                    {{phase['phase']['startAtText']}}
                </div>
            </div>
            <div class="phase-sign-status d-none d-lg-block">
                <div class="phase-sign-item-status">

                </div>
            </div>
            <div class="phase-sign-action">
                <button class="btn btn-secondary"
                    (click)="updatePhaseSigns(phase['phase'], phase['phase']['activeStateIndex'])"><i
                        class="bi bi-check2-all"></i></button>
            </div>
        </div>
        <div [ngClass]="{
            'd-none': collapse[phase['phase']['id']] != true
        }">
            <div *ngFor="let cat of phase['printSigns'] | keyvalue">
                <div *ngFor="let sign of phase['printSigns'][cat.key]; let i = index"
                    class="phase-list-row sign {{cat.key}}-p{{phase['phase']['number']}}"
                    [ngClass]="{'hidden' : !collapsedSigns[cat.key + '-p' + phase['phase']['number']] && i != 0, 'grayBG' : i > 0}">
                    <div class="phase-list-collapse d-none d-lg-block">
                    </div>
                    <div class="phase-list-col-1 d-none d-lg-block">
                        <div class="phase-list-date">

                        </div>
                        <div class="phase-sign-name">
                        </div>
                    </div>
                    <div class="phase-sign-status">
                        <span class="px-1" style="cursor: pointer;"
                            *ngIf="i == 0 && collapsedSigns[cat.key + '-p' + phase['phase']['number']]"
                            (click)="toggleSigns(cat.key + '-p' + phase['phase']['number'])"><i
                                class="bi bi-chevron-up"></i></span>
                        <span class="px-1" style="cursor: pointer;"
                            *ngIf="i == 0 && !collapsedSigns[cat.key + '-p' + phase['phase']['number']]"
                            (click)="toggleSigns(cat.key + '-p' + phase['phase']['number'])"><i
                                class="bi bi-chevron-down"></i></span>
                        <img [src]="sign['inventoryImage']" class="sign-thumb" *ngIf="sign['inventoryImage']">
                        {{sign['inventoryName']}} <small class="text-muted"
                            *ngIf="sign['parentId'] || sign['childId']">(Doorgeplaatst)</small>
                    </div>
                    <div class="phase-sign-action">
                        <ng-container *ngFor="let index of [phase['phase']['activeStateIndex']]">
                            <div class="phase-sign-item-status">

                                <button *ngIf="i == 0" (click)="updateSignByType(cat.key, cat, index)" class="btn btn-sign-status btn-ready-all"
                                    class="btn btn-secondary"><i class="bi bi-check2-all"></i></button>

                                <button class="btn btn-sign-status btn-ready"
                                    (click)="changeSignStatus(sign, index, 'READY')"
                                    [ngClass]="{'selected': sign['status'+index+''] == 'READY'}"><span>Gereed</span></button>
                                <button class="btn btn-sign-status btn-not-ready"
                                    (click)="changeSignStatus(sign, index, 'NOT_READY')"
                                    [ngClass]="{'selected': sign['status'+index+''] == 'NOT_READY'}"><span>Niet
                                        gereed</span></button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>