import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

import { HttpClientModule } from '@angular/common/http';

import { ReactiveFormsModule } from '@angular/forms';
import { OrganisationsIndexComponent } from './pages/organisations-index/organisations-index.component';
import { LoginComponent } from './pages/login/login.component';
import { OrganisationsFormComponent } from './pages/organisations-form/organisations-form.component';
import { AuthGuard } from './services/core/authguard.service';

import { FormsModule } from '@angular/forms';
import { ModalContentComponent } from './modals/modal-content/modal-content.component';
import { ModalConfirmDeleteComponent } from './modals/modal-confirm-delete/modal-confirm-delete.component';
import { HasErrorPipe } from './pipes/has-error.pipe';
import { UsersIndexComponent } from './pages/users-index/users-index.component'; 

import { ConfigService }  from './services/core/config.service';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NotauthorizedComponent } from './pages/notauthorized/notauthorized.component';

import { registerLocaleData } from '@angular/common';
import LocaleNl from '@angular/common/locales/nl';

import { ProjectCalculationItemsComponent } from './pages/projects-detail/project-calculation-items/project-calculation-items.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { TableGuruComponent } from './components/table-guru/table-guru.component';
import { AddFilterComponent } from './components/table-guru/modals/add-filter/add-filter.component';
import { EditFilterComponent } from './components/table-guru/modals/edit-filter/edit-filter.component';
import { UserEditModalComponent } from './components/users/user-edit-modal/user-edit-modal.component';
import { ProjectsDetailComponent } from './pages/projects-detail/projects-detail.component';
import { ProjectInfoFormComponent } from './components/projects/project-info-form/project-info-form.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ProjectInfoFilesComponent } from './components/projects/project-info-files/project-info-files.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { UserGroupsIndexComponent } from './pages/user-groups-index/user-groups-index.component';
import { UserGroupsFormComponent } from './pages/user-groups-form/user-groups-form.component';
import { QuoteAcceptedFormComponent } from './components/projects/quote-accepted-form/quote-accepted-form.component';
import { QuoteStatusFormComponent } from './components/projects/quote-status-form/quote-status-form.component';
import { ProjectsIndexComponent } from './pages/projects-index/projects-index.component';
import { ArchiveIndexComponent } from './pages/archive-index/archive-index.component';
import { FilePreviewComponent } from './modals/file-preview/file-preview.component';
import { QualityComponent } from './pages/quality/quality.component';
import { ProjectHoursComponent } from './pages/projects-detail/project-hours/project-hours.component';
import { HoursComponent } from './pages/hours/hours.component';
import { ProjectHoursUsersComponent } from './pages/projects-detail/project-hours/project-hours-users/project-hours-users.component';
import { WeekFormComponent } from './components/week-form/week-form.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProjectPermitStatusComponent } from './pages/projects-detail/project-permit-status/project-permit-status.component';
import { ProjectFilesComponent } from './pages/projects-detail/project-files/project-files.component';
import { QuickViewClientComponent } from './quick-views/quick-view-client/quick-view-client.component';
import { QuickViewContactComponent } from './quick-views/quick-view-contact/quick-view-contact.component';
import { QuickViewUserGroupComponent } from './quick-views/quick-view-user-group/quick-view-user-group.component';
import { QuickViewUserSiteManagerComponent } from './quick-views/quick-view-user-site-manager/quick-view-user-site-manager.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SetupPasswordComponent } from './pages/setup-password/setup-password.component';
import { AuthenticationService } from './services/core/authentication.service';
import { EditFileNameComponent } from './pages/projects-detail/project-files/edit-file-name/edit-file-name.component';
import { SendQuoteComponent } from './pages/projects-detail/send-quote/send-quote.component';
import { QuoteMailsComponent } from './pages/projects-detail/quote-mails/quote-mails.component';
import { ProjectsOrderslipComponent } from './pages/projects-orderslip/projects-orderslip.component';
import { NotesComponent } from './pages/notes/notes.component';
import { PrintComponent } from './pages/print-index/print-index.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { PrintDetailsComponent } from './pages/print-details/print-details.component';
import { NotesFormComponent } from './pages/notes-form/notes-form.component';
import { InventoryFormComponent } from './pages/inventory/inventory-form/inventory-form.component';
import { ProjectPhasesComponent } from './pages/projects-detail/project-phases/project-phases.component';
import { ProjectAddSignModalComponent } from './pages/projects-detail/project-add-sign-modal/project-add-sign-modal.component';
import { ProjectAddSignListComponent } from './pages/projects-detail/project-add-sign-list/project-add-sign-list.component';
import { ProjectPhaseMutateComponent } from './pages/projects-detail/project-phase-mutate/project-phase-mutate.component';
import { ProjectMapComponent } from './pages/projects-detail/project-map/project-map.component';
import { ProjectPrintsComponent } from './pages/projects-detail/project-prints/project-prints.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectWorkordersComponent } from './pages/projects-detail/project-workorders/project-workorders.component';

registerLocaleData(LocaleNl);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#5c2d2d",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#001e79",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "pulse",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(255,255,255,0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

export function appInit(configService: ConfigService) {
  return () => configService.load();
}

export function appInit2(authenticationService: AuthenticationService) {
  return () => authenticationService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    OrganisationsIndexComponent,
    LoginComponent,
    OrganisationsFormComponent,
    ModalContentComponent,
    ModalConfirmDeleteComponent,
    HasErrorPipe,
    UsersIndexComponent, 
    NotauthorizedComponent,
    TableGuruComponent,
    AddFilterComponent,
    EditFilterComponent,
    UserEditModalComponent,
    ProjectsDetailComponent,
    ProjectInfoFormComponent,
    ProjectInfoFilesComponent,
    CalendarComponent,
    UserGroupsIndexComponent,
    UserGroupsFormComponent,
    QuoteAcceptedFormComponent,
    QuoteStatusFormComponent,
    ProjectsIndexComponent,
    ArchiveIndexComponent,
    FilePreviewComponent,
    QualityComponent,
    ProjectHoursComponent,
    HoursComponent, 
    ProjectHoursUsersComponent,
    ProjectCalculationItemsComponent,
    WeekFormComponent,
    MyProfileComponent,
    SettingsComponent,
    ProjectPermitStatusComponent,
    ProjectFilesComponent,
    QuickViewClientComponent,
    QuickViewContactComponent,
    QuickViewUserGroupComponent,
    QuickViewUserSiteManagerComponent,
    ForgotPasswordComponent,
    SetupPasswordComponent,
    EditFileNameComponent,
    SendQuoteComponent,
    QuoteMailsComponent,
    ProjectsOrderslipComponent,
    NotesComponent,
    PrintComponent,
    InventoryComponent,
    PrintDetailsComponent,
    NotesFormComponent,
    InventoryFormComponent,
    ProjectPhasesComponent,
    ProjectAddSignModalComponent,
    ProjectAddSignListComponent,
    ProjectPhaseMutateComponent,
    ProjectMapComponent,
    ProjectPrintsComponent,
    ProjectWorkordersComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    DragDropModule,
    NgxDropzoneModule,
    NgxPaginationModule
  ],
  providers: [
    AuthGuard,
	  ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigService]
    },
	  AuthenticationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit2,
      multi: true,
      deps: [AuthenticationService]
    },
    NgbActiveModal,
    { provide: LOCALE_ID, 
      useValue: "nl" },
   ],
  bootstrap: [AppComponent],
  entryComponents: [ ModalContentComponent ]
})
export class AppModule { }
