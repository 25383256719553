<section class="project-files project-files-{{type}}" [ngClass]="{
    'loader-overlay': loaderOverlay    
}"> 
        <div class="row">
            <div class="col-8 file-list" [ngClass]="{
                'modal-view': target != 'GENERAL' && target != 'CALCULATION'
            }"> 
                <div *ngIf="target == 'VERKEERSPLAN'"><br/></div>
                <div class="dropdown mb-3 d-inline-block" *ngIf="currentUser['role'] === 'ADMIN' && projectFiles && projectFiles.length > 0">  
                    <button 
                        class="btn btn-dark dropdown-toggle"
                        type="button"  
                        data-bs-toggle="dropdown"
                        (click)="toggleDropdown()"
                        >
                            Acties
                    </button>
                    <ul class="dropdown-menu" [ngClass]="{
                    'show': showDropDown == true
                    }" style="margin-top:0;margin-left:0;">
                        <li class="hide-on-modal"><a class="dropdown-item" (click)="setShowCategory(true)" href="javascript:;">Wijzig categorie</a></li>
                        <li><a class="dropdown-item" (click)="confirmDelete()" href="javascript:;">Verwijder</a></li>
                    </ul>
                </div>

                <span class="d-online-block" *ngIf="showCategoryChange">
                    <select (change)="handleCategoryChange($event)" 
                        [style.opacity]="targetCategory != '' ? '1' : '0.8'"
                        class="form-control ms-2 d-inline-block" style="width:240px;position:relative;top:1px;height:42.5px;"
                    >
                        <option selected value="">Wijzig naar categorie</option>
                        <option value="{{category.value}}" *ngFor="let category of fileCategoryOptions">
                            {{category.name}}
                        </option>
                    </select>

                    <button class="btn btn-dark ms-1 with-icon" *ngIf="targetCategory != ''" (click)="saveCategoryChange()" style="height: 40px;top: -1px;">
                        <i class="bi bi-arrow-right"></i> Toepassen
                    </button>

                    <button class="btn btn-light ms-1" (click)="setShowCategory(false)" style="height: 40px;top: -1px;">
                        Annuleren
                    </button>
                </span>
                <section class="table-grid">
                    <section class="table-content">
                        <div class="text-center py-5" *ngIf="projectFiles && projectFiles.length < 1">
                            <small>Geen resultaten</small>
                        </div>
                        <table class="table table-vertical-align mb-0" *ngIf="projectFiles && projectFiles.length > 0">
                            <thead class="thead-dark">
                                <tr>
                                    <th *ngIf="currentUser['role'] === 'ADMIN'">&nbsp;</th>
                                    <th>Bestand</th>
                                    <th *ngIf="hideMeta === false">Grootte</th>
                                    <th *ngIf="hideMeta === false">Uploader</th>
                                    <th *ngIf="hideMeta === false">Categorie</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let file of projectFiles; let i = index" [ngClass]="{
                                    'd-none': currentUser['role'] !== 'ADMIN' && file['category'] == 'QUOTE'
                                }">
                                    <td style="width: 60px;padding-top:20px" *ngIf="currentUser['role'] === 'ADMIN'">
                                        <span class="pe-2" (click)="toggleSelect(file['id'])">
                                            <i class="bi bi-square-fill check-inactive check-icon" *ngIf="!selectedFiles[file['id']]"></i>
                                            <i class="bi bi-check-square-fill check-icon" *ngIf="selectedFiles[file['id']]"></i>
                                        </span>
                                    </td>
                                    <td class="">
                                        <a [href]=" sanitize(file['openUrl']) ">
                                            <span  class="file-icon float-start me-2 mt-2" [innerHTML]="transform(file['icon'])"></span>
                                            <div style="float:left;max-width:150px;overflow: hidden;" title="{{file['name']}}">
                                                <p style="float:left;margin-bottom:4px;"><span style="white-space: nowrap;">{{file['name']}}</span>
                                                    <small class="text-primary d-block">{{file['createdAtText']}}</small>
                                                </p>
                                            </div>
                                        </a>
                                    </td>
                                    <td nowrap *ngIf="hideMeta === false">
                                        {{file['sizeMb']}}
                                    </td>
                                    <td nowrap *ngIf="hideMeta === false">
                                        {{file['createdByName']}}
                                    </td>
                                    <td nowrap *ngIf="hideMeta === false">
                                        {{file['categoryText']}}
                                    </td>
                                    <td nowrap style="text-align:right;">
                                        <span *ngIf="currentUser['role'] === 'ADMIN'" (click)="editFilename(project, file)" 
                                            style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                            class=""><i class="bi bi-pencil"></i></span>
                                        <span (click)="showPreview(file, projectFiles)" 
                                            style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                            class=""><i class="bi bi-search"></i></span>
                                        <span  *ngIf="currentUser['role'] === 'ADMIN' || fileType == 'projectPhotos'" (click)="confirmDelete2(file)" 
                                            style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                            class=""><i class="bi bi-trash"></i></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </section>
            </div>
            <div class="col-4 file-upload" (dragover)="dropzoneHovered = true" (dragleave)="dropzoneHovered = false" [ngClass]="{
                'has-files': projectFiles && projectFiles.length > 0,
                'hide-meta': hideMeta,
                'modal-view': target != 'GENERAL'  &&  target != 'CALCULATION'
            }">
                <div class="file-container" (dragover)="dropzoneHovered = true" (dragleave)="dropzoneHovered = false">
                    <section class="user-form" *ngIf="project">
                        <input type="hidden" *ngIf="project" class="form-control" id="id" [(ngModel)]="project['id']" name="id">
                        </section>
                        <section style="position:relative;" class="dropzone-container"  [ngClass]="{
                            'dropzone-hovered': dropzoneHovered
                        }">
                            <div [ngClass]="{'d-none': target == 'VERKEERSPLAN'}">
                                <section [ngClass]="{ 'd-none': currentUser['role'] !== 'ADMIN' && fileType != 'projectPhotos'}">
                                <ngx-dropzone #drop (change)="onSelect($event)" (dragover)="dropzoneHovered = true" (dragleave)="dropzoneHovered = false" class="dropzone">
                                    <ngx-dropzone-label><i class="bi bi-file-earmark-ruled"></i></ngx-dropzone-label>
                                    <ngx-dropzone-preview class="filename" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                                </section>
                                <button (click)="drop.showFileSelector()" class="btn btn-dark mt-3 mb-4" *ngIf="type != 'quick' && currentUser['role'] === 'ADMIN'">
                                    <i class="bi bi-file-earmark-arrow-up me-1"></i> <span *ngIf="target == 'GENERAL'">Kies bestanden</span><span *ngIf="target != 'GENERAL'">Upload bestanden</span>
                                </button>
                            </div>
                            <button (click)="drop.showFileSelector()" class="btn btn-light" *ngIf="type == 'quick' && projectFiles.length < 1 && currentUser['role'] === 'ADMIN'">
                                <i class="bi bi-file-earmark-arrow-up me-1"></i> 
                                <span *ngIf="target != 'GENERAL'">
                                    <span *ngIf="target == 'VERKEERSPLAN'">Upload verkeersplan</span>
                                    <span *ngIf="target != 'VERKEERSPLAN'">Upload bestanden</span>
                                    
                                </span>
                            </button>
                            <button (click)="editPageProjectPermitStatus (project, target)" class="btn btn-dark" *ngIf="type == 'quick' && projectFiles.length > 0">
                                <i class="bi bi-file-earmark-arrow-up me-1"></i> 
                                <span *ngIf="target != 'GENERAL'" >
                                    <span *ngIf="target == 'VERKEERSPLAN'">Bekijk verkeersplan</span>
                                    <span *ngIf="target != 'VERKEERSPLAN'">Bekijk bestanden</span>
                                </span>
                            </button>
                    </section>
                </div>
            </div>
        </div> 
</section>