<header class="page-header">
    <div class="row">
        <div class="col-8">
            <h1>Mijn profiel</h1>
            <h6>Wijzig je gegevens</h6>
        </div>
        <div class="col-4 text-end pt-3">
            <button class="btn btn-success with-icon" (click)="save()">
                Opslaan <i class="bi bi-save"></i>
            </button>
        </div>
    </div>
</header>

<section *ngIf="item" class="card p-4 mb-5" [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="row">

        <div class="col-12 col-lg-8">

            <div class="form-group pb-2">
                <label for="email" class="pb-1">E-mail</label>
                <input type="text" readonly *ngIf="item" class="form-control" value="{{item['email']}}"/>
            </div>

            <div class="form-group pb-2">
                <label for="name" class="pb-1">Voornaam</label>
                <input type="text" *ngIf="item" class="form-control" id="firstname" [(ngModel)]="item.firstname" name="firstname">
            </div>

            <div class="form-group pb-2">
                <label for="name" class="pb-1">Achternaam</label>
                <input type="text" *ngIf="item" class="form-control" id="lastname" [(ngModel)]="item.lastname" name="lastname">
            </div>

            <div class="form-group pb-2">
                <label for="name" class="pb-1">Bestandslocatie netwerkschijf</label>
                <input type="text" *ngIf="item" class="form-control" id="filePath" [(ngModel)]="item.filePath" name="filePath">
            </div>

			<div class="form-group mb-2">
				<label for="shortName" class="pb-1">Wijzig wachtwoord</label>
				<div class="form-check form-switch">
					<input type="checkbox" class="form-check-input" id="changepassword" role="switch" name="changepassword" [ngModelOptions]="{standalone: true}" [(ngModel)]="changepassword">
					<label class="form-check-label" for="changepassword"></label>
				</div>
			</div>

            <section  *ngIf="changepassword == true">
                <div class="form-group pb-2">
                    <label for="adminpassword" class="pb-1">Huidige wachtwoord ter controle</label>
                    <input type="password"
                        class="form-control"
                        id="adminpassword"
                        required
                        [(ngModel)]="item['adminpassword']"
                        (blur)="checkPassword($event.target.value)"
                    (keyup)="checkPassword($event.target.value)"
                    [ngClass]="{ 'is-invalid': (errorPassword ||  errors.adminpassword ), 'is-valid': passwordValid }"
                    value="{{password}}"
                    [(ngModel)]="password"
                        >
                    <small class="form-text text-muted">Vul hier je eigen wachtwoord om misbruik te voorkomen.</small>
                    <div class="error invalid-feedback" *ngIf="errors.adminpassword">{{errors.adminpassword}}</div>
                </div>
                <div class="form-group pb-2" *ngIf="item" >
                    <label for="newpassword" class="pb-1">Nieuw wachtwoord</label>
                    <input type="password" class="form-control" [ngClass]="{ 'is-invalid': errors.newpassword }"   id="newpassword" required  [(ngModel)]="item['newpassword']">
                    <div class="error invalid-feedback" *ngIf="errors.newpassword">{{errors.newpassword}}</div>
                </div>
                <div class="form-group pb-2" *ngIf="item" >
                    <label for="repeatpassword" class="pb-1">Herhaal wachtwoord</label>
                    <input type="password" class="form-control" [ngClass]="{ 'is-invalid': errors.repeatpassword }"   id="repeatpassword" required  [(ngModel)]="item['repeatpassword']">
                    <div class="error invalid-feedback" *ngIf="errors.repeatpassword">{{errors.repeatpassword}}</div>
                </div>
            </section>
        </div>

    </div>
</section>