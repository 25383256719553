import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AddFilterComponent } from './modals/add-filter/add-filter.component';
import { EditFilterComponent } from './modals/edit-filter/edit-filter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-table-guru',
  templateUrl: './table-guru.component.html',
  styleUrls: ['./table-guru.component.scss']
})
export class TableGuruComponent implements OnInit {
  @Input() public link;
  @Input() public refresh;
  @Input() public mapping;
  @Input() public editPath;
  @Input() public showEditButtons = false;
  @Output() editAction = new EventEmitter();
  @Output() deleteAction = new EventEmitter();
  
  loading = false;
  items;
  total; 
  pages; 
  page = 1;
  limit = '';
  keyword = ''; 
  filters = []; 
  filterOptions = []; 
  sort = '';
  direction = 'ASC';
  pagesCounter = [];
  globalTimeout = null; 
  path = ''; 

  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private app: AppService,
    private apiService: ApiConnectionService
  ) {

  }

  ngOnInit(): void 
  {
    this.path = this.link;
    this.all();
  }
  
  ngOnChanges(changes: any) {
    this.path = this.link;
    this.all();
  }

  addFilter ()
  {
  	let self = this;
  	
    const filterModal = this.modalService.open(AddFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.result.then((result) => {
      if (result && result.length > 0) {
        self.editFilter(result);
      }
    })
    .catch((error) => {
    });
  }
  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
 }
  editFilter (filterKey)
  {
  	let self = this;
    const filterModal = this.modalService.open(EditFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.componentInstance.filters = self.filters;
    filterModal.componentInstance.activeFilterKey = filterKey;
    
    filterModal.result.then((result) => {
     
      if (result && result['action'] === "remove") {
        this.removeFilter(result['key']);
      }
      else if (result) {
        self.pushFilter(result);
        self.all();
      }
    })
    .catch((error) => {
    });
  }

  removeFilter (key)
  {
    let self = this;
    if (this.filters)
    {
      for (let i=0;i<this.filters.length;i++)
      {
        let filter = this.filters[i];
        if (filter['key'] === key)
        {
          this.filters.splice(i,1);
        }
      }
    }
    /*
    * TODO: handle in storage function
    */
    if (this.filters.length === 0)
    {
      let filterStorageItem = localStorage.getItem('filters');
      let filterStorage = {};
      if (filterStorageItem)
      {
        filterStorage = JSON.parse(filterStorageItem);
      }

      filterStorage[this.path] = {};

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    self.all();
  }

  pushFilter (searchValues)
  {
    let key = searchValues['key'];
    let values = searchValues['values'];

    if (this.filters)
    {
      let filterOptions = [];
      for (let i=0;i<this.filters.length;i++)
      {
        let filter = this.filters[i];
        if (filter['key'] === key)
        {
          this.filters[i] = {
            key: key,
            values: values,
          };
          
          return true;
        }
      }
    }
    this.filters.push ( {
      key: key,
      values: values,
    });
    return true;
  }
  
  all () {
    if (this.loading === true)
    {
      /// do nothing
      return;
    }

    let filterStorageItem = localStorage.getItem('filters');
    let filterStorage = {};
    if (filterStorageItem)
    {
      filterStorage = JSON.parse(filterStorageItem);
    }

    let url = this.path;
        url += '&data[page]=' + this.page;
        url += '&data[limit]=' + this.limit;
        url += '&data[sort]=' + this.sort;
        url += '&data[direction]=' + this.direction;

    let filterOption;    
        if (this.keyword.length > 1)
        {
          url += '&data[keyword]=' + this.keyword;
        }

        if (this.filters && this.filters.length > 0)
        {
          let filterOptions = [];
          for (let i=0;i<this.filters.length;i++)
          {
            if (this.filters[i].values)
            {
              filterOptions.push('data[filters]['+this.filters[i].key+'][values]='+encodeURIComponent( this.filters[i].values.join('||') )+'');
            }
          }
          filterOption = filterOptions.join('&');
          // url += '&' + filterOption;
          
          /*
          * TODO: storage function
          */
          filterStorage[this.path] = {filters: filterOption};

          localStorage.setItem('filters', JSON.stringify(filterStorage));
        }
        else if (filterStorage[this.path])
        {
            let values = filterStorage[this.path]['filters'];
            // url += '&' + values;
            filterOption = values;
        }

    let self = this;
    self.loading = true;
    this.app.setLoaderStatus(true);


    this.apiService.save(url, filterOption).then((response) => {
        self.loading = false;
        this.app.setLoaderStatus(false);

        self.items = response['items'];
        self.total = response['total'];
        self.pages = response['pages'];
        self.page = response['page'];
        self.limit = response['limit'];
        self.filterOptions = response['filterOptions'];

        if (self.keyword.length < 1)
        {
          self.keyword = response['keyword'];
        }

        // if (self.filters.length < 1)
        // {
          self.filters = response['filters'];
        // }
        
        self.sort = response['sort'];
        self.direction = response['direction'];

        self.pagesCounter = [];
        for (let i=1;i<=self.pages;i++)
        {
          self.pagesCounter.push(i);
        }

    }).catch (function (error) {
    });
  }

  doSearch (e)
  {
    let self = this;
    if (self.globalTimeout != null) {
      clearTimeout(self.globalTimeout);
    }
    self.globalTimeout = setTimeout(function() {
      self.globalTimeout = null;  
      self.all();
    }, 500);
  }

  getPage(number)
  {
    this.page = number;
    this.all();
  }

  getPrevious ()
  {
    this.page--;
    if (this.page < 1) {
      this.page = 1
    }
    this.all();
  }

  getNext ()
  {
    this.page++;
    if (this.page > this.pages) {
      this.page = this.pages
    }
    this.all();
  }

  edit (item)
  {
    this.editAction.emit(item);
  }

  delete (item)
  {
    this.deleteAction.emit(item);
  }

  setSort (sort) 
  {
    this.sort = sort;
    if (this.direction === 'ASC') {
      this.direction = 'DESC';
    } else {
      this.direction = 'ASC';
    }
    this.all();
  }
}
