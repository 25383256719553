<div class="py-5 text-center" *ngIf="items.length < 1">
    <p>
        Nog geen fases toegevoegd
    </p>
    <button (click)="addPhase({number: '', projectId: project['id']})" class="btn btn-success with-icon">Toevoegen <i
            class="bi bi-plus-circle-fill"></i></button>
</div>
<div>
    <section class="phases-list" *ngIf="items.length > 0">
        <div class="phase-list-header">
            <div class="phase-list-collapse">
            </div>
            <div class="phase-list-col-1">
                <div class="phase-list-date">
                    Datum
                </div>
                <div class="phase-list-number">
                    Fase
                </div>
                <div class="phase-sign-name">
                    Bord
                </div>
            </div>
            <div class="phase-sign-status">
                Fases
            </div>
            <div class="phase-sign-action">
            </div>
        </div>
        <section class="phase-item-container" *ngFor="let phase of items; let i = index">
            <div class="phase-list-row header">
                <div class="phase-list-collapse">
                    <span class="px-1" style="cursor: pointer;" (click)="changeCollapse(phase['id'], true)" [ngClass]="{
                    'd-none': phaseCollapse[phase['id']] == true
                }">
                        <i class="bi bi-chevron-down"></i>
                    </span>
                    <span class="px-1" style="cursor: pointer;" (click)="changeCollapse(phase['id'], false)" [ngClass]="{
                    'd-none': phaseCollapse[phase['id']] != true
                }">
                        <i class="bi bi-chevron-up"></i>
                    </span>
                </div>
                <div class="phase-list-col-1">
                    <div class="phase-list-date">
                        {{phase['startAtText']}}
                    </div>
                    <div class="phase-list-number">
                        Fase {{phase['number']}} <span *ngIf="phase['isFinished'] == true"><i
                                class="bi bi-check2-all"></i></span>
                    </div>
                    <div class="phase-sign-name">

                    </div>
                </div>
                <div class="phase-sign-status">
                    <div class="phase-sign-item-status" [ngClass]="{
                    'status-not-active': !phase['status1Active']
                }">Bedrukking</div>
                    <div class="phase-sign-item-status" [ngClass]="{
                    'status-not-active': !phase['status2Active']
                }">Voorplaatsen</div>
                    <div class="phase-sign-item-status" [ngClass]="{
                    'status-not-active': !phase['status3Active']
                }">Opdraaien</div>
                    <div class="phase-sign-item-status" [ngClass]="{
                    'status-not-active': !phase['status4Active']
                }">Werkloop</div>
                    <div class="phase-sign-item-status" [ngClass]="{
                    'status-not-active': !phase['status5Active']
                }">Bijplaatsen</div>
                    <div class="phase-sign-item-status" [ngClass]="{
                    'status-not-active': !phase['status6Active']
                }">Verwijderen</div>
                </div>
                <div class="phase-sign-action">
                    <span (click)="editPhase(phase)" style="opacity:0.5;">
                        <i class="bi bi-pencil add2-icon row-icon me-2"></i>
                    </span>
                    <span (click)="addPhase({number: '', projectId: project['id']})">
                        <i class="bi bi-plus-lg add2-icon row-icon"></i>
                    </span>
                </div>
            </div>
            <div [ngClass]="{
            'd-none': phaseCollapse[phase['id']] != true
        }">
                <div class="phase-list-row actions">
                    <div class="phase-list-collapse">
                    </div>
                    <div class="phase-list-col-1">
                        <div class="phase-sign-info">
                        </div>
                    </div>
                    <div class="phase-sign-status">
                        <div class="phase-sign-item-status check-all" [ngClass]="{
                        'status-not-active': !phase['status1Active']
                    }"><button class="btn btn-secondary" (click)="updatePhaseSigns(phase, 1)"><i
                                    class="bi bi-check2-all"></i></button></div>
                        <div class="phase-sign-item-status check-all" [ngClass]="{
                        'status-not-active': !phase['status2Active']
                    }"><button class="btn btn-secondary" (click)="updatePhaseSigns(phase, 2)"><i
                                    class="bi bi-check2-all"></i></button></div>
                        <div class="phase-sign-item-status check-all" [ngClass]="{
                        'status-not-active': !phase['status3Active']
                    }"><button class="btn btn-secondary" (click)="updatePhaseSigns(phase, 3)"><i
                                    class="bi bi-check2-all"></i></button></div>
                        <div class="phase-sign-item-status check-all" [ngClass]="{
                        'status-not-active': !phase['status4Active']
                    }"><button class="btn btn-secondary" (click)="updatePhaseSigns(phase, 4)"><i
                                    class="bi bi-check2-all"></i></button></div>
                        <div class="phase-sign-item-status check-all" [ngClass]="{
                        'status-not-active': !phase['status5Active']
                    }"><button class="btn btn-secondary" (click)="updatePhaseSigns(phase, 5)"><i
                                    class="bi bi-check2-all"></i></button></div>
                        <div class="phase-sign-item-status check-all" [ngClass]="{
                        'status-not-active': !phase['status6Active']
                    }"><button class="btn btn-secondary" (click)="updatePhaseSigns(phase, 6)"><i
                                    class="bi bi-check2-all"></i></button></div>
                    </div>
                    <div class="phase-sign-action">

                    </div>
                </div>

                <ng-container *ngFor="let sign of phase['phaseSigns']">
                    <div class="phase-list-row sign">
                        <div class="phase-list-collapse">
                        </div>
                        <div class="phase-list-col-1">
                            <div class="phase-list-date">
                                <span class="px-1" style="cursor: pointer;" (click)="toggleCollapse(i + sign['inventoryId'])" *ngIf="!subSignCollapse[i + sign['inventoryId']]">
                                    <i class="bi bi-chevron-down"></i>
                                </span>
                                <span class="px-1" style="cursor: pointer;" (click)="toggleCollapse(i + sign['inventoryId'])" *ngIf="subSignCollapse[i + sign['inventoryId']]">
                                    <i class="bi bi-chevron-up"></i>
                                </span>
                            </div>
                            <div class="phase-list-number">
                                
                            </div>
                            <div class="phase-sign-name">
                                <img [src]="sign['inventoryImage']" class="sign-thumb" *ngIf="sign['inventoryImage']">
                                {{sign['inventoryName']}} <small>{{sign['itemCount']}} borden</small> <small class="text-muted"
                                    *ngIf="sign['parentId'] || sign['childId']">(Doorgeplaatst)</small>
                            </div>
                        </div>
                        <div class="phase-sign-status">
                            <ng-container *ngFor="let index of [1,2,3,4,5,6]">
                                <div class="phase-sign-item-status" [ngClass]="{
                                'status-not-active': !phase['status'+index+'Active']
                            }">
                                    <button class="btn btn-sign-status btn-ready"
                                        (click)="updateSignByType(sign['items'], index, 'READY')"
                                        [ngClass]="{'selected': sign['status']['status'+index+''] == 'READY'}"><span>Gereed</span></button>
                                    <button class="btn btn-sign-status btn-not-ready"
                                    (click)="updateSignByType(sign['items'], index, 'NOT_READY')"
                                        [ngClass]="{'selected': sign['status']['status'+index+''] == 'NOT_READY'}"><span>Niet
                                            gereed</span></button>
                                    <button class="btn btn-sign-status btn-na" (click)="updateSignByType(sign['items'], index, 'NA')"
                                        [ngClass]="{'selected': sign['status']['status'+index+''] == 'NA'}"><span></span>N.V.T.</button>
                                </div>
                            </ng-container>
                        </div>
                        <div class="phase-sign-action">
                            <span class="me-3 row-icon delete2-icon" (click)="confirmDelete(sign)">
                                <i class="bi bi-trash-fill"></i>
                            </span>
                        </div>
                    </div>

                    <div class="phase-list-row sign" *ngFor="let subsign of sign['items']" [ngClass]="{'collapsed' : subSignCollapse[i + subsign['inventoryId']] != true}">
                        <div class="phase-list-collapse">
                        </div>
                        <div class="phase-list-col-1">
                            <div class="phase-list-date">
                            </div>
                            <div class="phase-list-number">
                            </div>
                            <div class="phase-sign-name">
                                <img [src]="subsign['inventoryImage']" class="sign-thumb" *ngIf="subsign['inventoryImage']">
                                {{sign['inventoryName']}} <small class="text-muted"
                                    *ngIf="subsign['parentId'] || subsign['childId']">(Doorgeplaatst)</small>
                            </div>
                        </div>
                        <div class="phase-sign-status">
                            <ng-container *ngFor="let index of [1,2,3,4,5,6]">
                                <div class="phase-sign-item-status" [ngClass]="{
                                'status-not-active': !phase['status'+index+'Active']
                            }">
                                    <button class="btn btn-sign-status btn-ready"
                                        (click)="changeSignStatus(subsign, index, 'READY')"
                                        [ngClass]="{'selected': subsign['status'+index+''] == 'READY'}"><span>Gereed</span></button>
                                    <button class="btn btn-sign-status btn-not-ready"
                                        (click)="changeSignStatus(subsign, index, 'NOT_READY')"
                                        [ngClass]="{'selected': subsign['status'+index+''] == 'NOT_READY'}"><span>Niet
                                            gereed</span></button>
                                    <button class="btn btn-sign-status btn-na" (click)="changeSignStatus(subsign, index, 'NA')"
                                        [ngClass]="{'selected': subsign['status'+index+''] == 'NA'}"><span></span>N.V.T.</button>
                                </div>
                            </ng-container>
                        </div>
                        <div class="phase-sign-action">
                            <span class="me-3 row-icon delete2-icon" (click)="confirmDelete(sign)">
                                <i class="bi bi-trash-fill"></i>
                            </span>
                        </div>
                    </div>

                </ng-container>

                <div class="phase-list-row add-sign">
                    <div class="phase-list-col-1">
                        <div class="phase-list-date">

                        </div>
                        <div class="phase-list-number">

                        </div>
                        <div class="phase-sign-name">
                            <span class="phase-sign-add-btn" (click)="addSign(phase)"
                                style="cursor:pointer;margin-left:30px;">
                                <i class="bi bi-plus-lg add2-icon row-icon"></i> Bord toevoegen
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</div>