<section class="table-content" *ngIf="loading">
	<div class="text-center py-5" >
		<img style="width:140px;" src="/assets/images/loader.gif">
	</div>
</section>
<section *ngIf="!loading">
    <header class="page-header">
        <div class="row">
            <div class="col-8">
                <h1><span *ngIf="item['isQuote']">Offertes</span><span *ngIf="!item['isQuote']">Projecten</span> / {{item['number']}}</h1>
                <h6><span *ngIf="item['isQuote']">Offertes</span><span *ngIf="!item['isQuote']">Projecten</span> / {{item['name']}}</h6>
            </div>
            <div class="col-4 text-end pt-3">
                <button (click)="openForm(item['id'])" class="btn btn-success with-icon" *ngIf="currentUser['role'] == 'ADMIN'">
                    Bewerken <i class="bi bi-pencil"></i>
                </button>
            </div>
        </div>
    </header>

    <div id="detailContainer" class="mb-3 shadow-small">
        <div class="row ps-3">
            <div class="col-sm-6">
                <h5>{{item['name']}}</h5>
                <p *ngIf="item['longName']" class="pt-0 pb-3 mb-0" style="margin-top:-6px;"><span class="text-muted">{{item['longName']}}</span></p>
                <p class="mb-4">
                    <i class="bi bi-calendar3" style="position:relative;top:1px;margin-right:5px;"></i> Aangemaakt op: {{item['requestedAtText']}}
                    <i class="bi bi-calendar3 ms-3" style="position:relative;top:1px;margin-right:5px;"></i> Uitvoering op: {{item['startAtText']}} <span *ngIf="item['endAt']">- {{item['endAtText']}}</span>
                </p>
            </div>
            <div class="col-sm-6 align-right">
                <div style="position: relative;" *ngIf="currentUser['role'] == 'ADMIN'">
                    <div [innerHTML]="item['statusLabel']" (click)="toggleQuoteChange()" class="quote-change cursor-pointer"></div>
                    <div id="statusBox" *ngIf="quoteChange">
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-project-{{status['css']}}" (click)="changeStatus(status['value'])" *ngFor="let status of statusOptions" style="width:100%">{{status['name']}}</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div style="position: relative;" *ngIf="currentUser['role'] != 'ADMIN'">
                    <div [innerHTML]="item['statusLabel']" class="quote-change"></div>
                    <div id="statusBox" *ngIf="quoteChange">
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-project-{{status['css']}}" (click)="changeStatus(status['value'])" *ngFor="let status of statusOptions" style="width:100%">{{status['name']}}</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="d-flex project-contact-wrapper">
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <span class="contact-details-button" *ngIf="item['organisationId']" (click)="quickViewClient()"></span>
                    <span class="icon-button" style="background-color: #FFE7E7;color: #ff7e7e;"><i class="bi bi-briefcase"></i></span>
                    <div>
                        <p>Klant</p>
                        <p>{{item['organisationName']}}</p>
                    </div>
                </div>
            </div>
            
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <span class="contact-details-button" *ngIf="item['organisationId']" (click)="quickViewContact()"></span>
                    <span class="contact-details-button" *ngIf="!item['organisationId']" (click)="openForm({})"></span>
                    <span class="icon-button" style="background-color: #FCE9D9;color: #f3a45e;"><i class="bi bi-person-square"></i></span>
                    <div>
                        <p>Contactpersoon</p>
                        <p>{{item['contactPersonName']}}</p>
                    </div>
                </div>
            </div>
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['userGroupId']
                }">
                    <span class="contact-details-button" *ngIf="item['userGroupId']" (click)="quickViewUserGroup()"></span>
                    <span class="contact-details-button" *ngIf="!item['userGroupId']" (click)="openFormProject()"></span>
                    <span class="icon-button" style="background-color:#E0F0FA;color: #2D9CDB;"><i class="bi bi-person-gear"></i></span>
                    <div>
                        <p>Ploeg</p>
                        <p *ngIf="!item['userGroupName']">Onbekend</p>
                        <p *ngIf="item['userGroupName']">{{item['userGroupName']}}</p>
                    </div>
                </div>
            </div>
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['siteManagerId']
                }">
                    <span class="contact-details-button" *ngIf="item['siteManagerId']" (click)="quickViewSiteManager()"></span>
                    <span class="contact-details-button" *ngIf="!item['siteManagerId']" (click)="openFormProject()"></span>
                    <span class="icon-button" style="background-color:#D9F3EA;color: #47C69A;"><i class="bi bi-person"></i></span>
                    <div>
                        <p>Uitvoerder</p>
                        <p>{{item['siteManagerName']}}</p>
                    </div>
                </div>
            </div>
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['authorId']
                }">
                    <span class="contact-details-button" *ngIf="item['authorId']" (click)="quickViewAuthor()"></span>
                    <span class="contact-details-button" *ngIf="!item['authorId']" (click)="openForm({})"></span>
                    <span class="icon-button" style="background-color: #EBE9FD;color: #9F74D2;"><i class="bi bi-pencil-square"></i></span>
                    <div>
                        <p>Aanmaker</p>
                        <p *ngIf="!item['authorName']">Onbekend</p>
                        <p *ngIf="item['authorName']">{{item['authorName']}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3 shadow-small" id="subNav">
        <ul>
            <li (click)="changeTab('quote')" [ngClass]="{'active' : tabs['quote'] == true}">
                <i class="bi bi-info-circle"></i> 
                Offerte
            </li>
            <li (click)="changeTab('phases')" [ngClass]="{'active' : tabs['phases'] == true}">
                <i class="bi bi-check2-all"></i> 
                Fases
            </li>
            <li (click)="changeTab('prints')" [ngClass]="{'active' : tabs['prints'] == true}">
                <i class="bi bi-printer"></i>
                Bedrukkingen
            </li>
            <li (click)="changeTab('costs')" [ngClass]="{'active' : tabs['costs'] == true}">
                <i class="bi bi-info-circle"></i> 
                Kostenoverzicht
            </li>
            <li (click)="changeTab('map')" [ngClass]="{'active' : tabs['map'] == true}">
                <i class="bi bi-map"></i> 
                Kaart
            </li>
            <li (click)="changeTab('photos')" [ngClass]="{'active' : tabs['photos'] == true}">
                <i class="bi bi-image"></i> 
                Foto's
            </li>
            <li (click)="changeTab('hours')" [ngClass]="{'active' : tabs['hours'] == true}">
                <i class="bi bi-alarm"></i> 
                Urenregistratie
            </li>
        </ul>
    </div>
    <section class="tab-content mb-5">

        <div *ngIf="tabs['quote'] == true">
            <app-project-calculation-items [project]="item" [type]="'QUOTE'"></app-project-calculation-items>
        </div>

        <div *ngIf="tabs['phases'] == true">
            <app-project-phases [project]="item"></app-project-phases>
        </div>

        <div *ngIf="tabs['prints'] == true">
            <app-project-prints [project]="item"></app-project-prints>
        </div>

        <div *ngIf="tabs['costs'] == true">
            <app-project-calculation-items [project]="item" [type]="'COSTS'"></app-project-calculation-items>
        </div>

        <div *ngIf="tabs['map'] == true">
            <app-project-map [item]="item"></app-project-map>
        </div>

        <div *ngIf="tabs['photos'] == true">
            <app-project-files [project]="item" [fileType]="'projectPhotos'"></app-project-files>
        </div>

        <div *ngIf="tabs['hours'] == true">
            <app-project-hours [project]="item"></app-project-hours>
        </div>

    </section>
</section>