<header class="page-header">
    <div class="row">
        <div class="col-8">
            <h1>Instellingen</h1>
        </div>
    </div>
</header>

<section>
    <div class="row">
        <div class="col-4">
            <section class="card">
                <div class="card-body">
                    <h5 class="card-title">Project instellingen</h5>
                    <a routerLink="/settings/calculation-templates" class="d-block" style="text-decoration:none;margin-top:30px;color:#000000">
                        <span class="d-block" style="font-size:120%;">Calculatie templates</span>
                        <small class="text-muted">Beheer de prijzen en onderdelen van meerdere templates.</small>
                    </a>
                </div>
            </section>
        </div>
        <div class="col-4">
            <section class="card">
                
            </section>
        </div>
        <div class="col-4">
            <section class="card">
                
            </section>
        </div>
    </div>
</section>