<div class="modal-header">
	<h5 class="modal-title">
        Ploegen
	</h5>
	<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
 </div>
 <div class="modal-body">	
	<div class="alert alert-success" *ngIf="addedmessage">Nieuwe ploeg toegevoegd.</div>
	<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
	<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>
	<div class="alert alert-danger" *ngIf="deletemessage">Ploeg is succesvol verwijderd</div>

    <section class="" style="margin-left:-15px;margin-right:-15px;">
        <div class="text-center py-5" *ngIf="items && items.length < 1">
            <small>Geen resulaten</small>
        </div>
        <table class="table table-striped" *ngIf="items && items.length > 0">
            <thead class="thead-dark">
                <tr>
                    <th width="100"></th>
                    <th>Naam</th>
                    <th>Actief</th>
                    <th>Aantal</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items">
                    <td><span class="user-group-color-icon" [ngStyle]="{'background-color': item['color']}">{{item['shortName']}}</span></td>
                    <td style="vertical-align: middle;">{{item['name']}}</td>
                    <td style="vertical-align: middle;" [innerHTML]="item['activeLabel']"></td>
                    <td style="vertical-align: middle;" [innerHTML]="item['userCount']"></td>
                    <td style="text-align:right;">  
                        <span style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;" (click)="edit(item)"><i class="bi bi-pencil"></i></span>
                        <span style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;" class="delete-link" (click)="confirmDelete(item)"><i class="bi bi-trash"></i></span>
                   </td>
                </tr>
            </tbody>
        </table>
    </section>
</div> 
<div class="modal-footer">
    <button class="btn btn-success with-icon" (click)="openForm('')">Nieuwe ploeg <i class="bi bi-plus-circle-fill"></i></button>
</div>  