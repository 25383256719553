<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">{{file['name']}}</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1">
        <div style="height:0;opacity:0;"><input type="text" value=""></div>
        <div class="text-center" *ngIf="!file['hasPreview']">
            <div class="text-muted" style="padding:60px 0px;">Geen preview mogelijk. Download of open het bestand.</div>
        </div>
        <div class="file-preview-container" *ngIf="file['hasPreview']">
            <div [innerHTML]="content"></div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <span class="btn btn-light float-end" *ngIf="fileList && fileList.length > 0" [ngClass]="{ disabled: !nextFile }" (click)="setCurrentFile(nextFile)"><i class="bi bi-arrow-right"></i></span>
        <span class="btn btn-light float-end" *ngIf="fileList && fileList.length > 0" [ngClass]="{ disabled: !previousFile }" (click)="setCurrentFile(previousFile)"><i class="bi bi-arrow-left"></i></span>

        <a [href]="sanitize( file['openUrl'] )" class="btn btn-dark with-icon"> Open bestand <i class="bi bi-arrow-right"></i></a>
        <a href="{{file['downloadUrl']}}" class="btn btn-success with-icon">Download bestand <i class="bi bi-cloud-arrow-down"></i></a>
    </div>
</div>