import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { NotesFormComponent } from '../notes-form/notes-form.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  link = 'notes';
  refresh = 0;
  editPath = 'notes';
  mapping = [
    { key: 'id', label: 'ID', sort: true },
    { key: 'name', label: 'Gebruiker', sort: true },
    { key: 'note', label: 'Notitie', sort: true },
    { key: 'date', label: 'Datum', sort: true }
  ];

  currentUser;

  constructor(private appService: AppService, private modalService: NgbModal) {
    this.setPageDetails();
  }

  ngOnInit(): void {
  }

  private setPageDetails() {
    this.appService.setPageTitle('Notities');
    this.appService.setNavKey('notes');
  }

  editRow(item) {
    this.openForm({ id: item['id'] });
  }

  deleteRow(item) {
    this.confirmDelete(item);
  }

  confirmDelete(user) {
    let self = this;

    if (self.currentUser['role'] != 'ADMIN' && user['role'] == 'ADMIN') {
      alert('Je hebt geen rechten om deze gebruiker te verwijderen');
      return;
    }

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je ' + user.name + ' wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(user);
      }
    });
  }

  all() {

  }

  delete(user) {
   
  }

  openForm(data) {
    let self = this;

    const filterModal = this.modalService.open(NotesFormComponent, {
      windowClass: 'modal-right',
      backdrop: 'static',
      size: 'lg'
    });

    filterModal.componentInstance.id = data['id'];
    filterModal.componentInstance.closeAfterSave = true;
    filterModal.componentInstance.organisationId = data['organisationId'];
    filterModal.componentInstance.roleKey = data['role'];
    filterModal.result.then((result) => {
      self.all();
      self.refresh = Math.random();
    })
      .catch((error) => {
      });
  }

  openClientForm (data)
  {
	  let self = this;
	
	  sessionStorage.setItem('organisation-tab', 'info');
	  const filterModal = this.modalService.open(NotesFormComponent, {
		animation: true,
		windowClass: 'modal-right',
		backdrop: 'static',
		size: 'lg'
	  });
	  
	  filterModal.componentInstance.id = data['id'];
	  filterModal.result.then((result) => {
		self.refresh = Math.random();
	  })
	  .catch((error) => {
	  });
  }
}
