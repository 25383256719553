import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss']
})
export class ProjectMapComponent implements OnInit {
  @Input() item;
  public file;
  public imageHtml;
  public refresh = 1;
  public modal = false;

  constructor(
    private api: ApiConnectionService,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { 
  }

  ngOnInit(): void {
    this.fetchItem();
  }

  ngOnChanges(changes: any) {
    this.fetchItem();
  }

  fetchItem ()
  {
    let self = this;
    this.api.read('projects/verkeersplan/'+self['item']['id']+'').then (function (response) {
      self.file = response['file'];
      let html = '<div id="my-zoomist" data-zoomist-src="'+self.file['imageLink']+'"></div';
      self.imageHtml = self.sanitizer.bypassSecurityTrustHtml(html);
      setTimeout(function () {
        window['zoom']();
      }, 500);
    });
  }
  
	confirm () {

	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}

}
