import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  items = [];
  
  constructor(
    private app: AppService
  ) { }

  ngOnInit(): void {
    this.app.setNavKey('settings');
    this.app.setPageTitle('Instellingen');
  }

}
