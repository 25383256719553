import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService }  from '../../services/core/authentication.service';
import { Router } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from 'src/app/modals/modal-content/modal-content.component';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	submitted;
	loginForm;
	error : boolean = false;
	loading = false;

	public currentUser : any;

  constructor(
    private api: ApiConnectionService,
    private router: Router,
  	private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {

  }

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]]
		});
	}
	
	get f() { return this.loginForm.controls; }

  showMessage ()
  {
  	let self = this;
  	
    const modal = this.modalService.open(ModalContentComponent, {
      backdrop: 'static'
    });
    modal.componentInstance.title = 'E-mail verstuurd';
    modal.componentInstance.message = 'Als het e-mailadres bekend is in het systeem wordt er een e-mail verstuurd naar het opgegeven adres.';
    modal.componentInstance.button = 'Doorgaan';
    modal.result.then((result) => {
      
    })
    .catch((error) => {

    });
  }
	
	onSubmit() {
		this.submitted = true;
		this.error = false;
		
		let self = this;

		if (this.loginForm.invalid) {
			return;
		}

		if(this.submitted)
		{
			self.loading = true;
			
			let email = this.loginForm.controls.email.value;
			
			this.api.save('users/passwordRecovery',{
        email: email
      }).then(function (response) {
        self.showMessage();
				self.loading = false;
			}).catch(function (error) {
        self.showMessage();
				self.loading = false;
				self.error = true;
			});
		}
	
	}
}
