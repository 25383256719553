import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-projects-orderslip',
  templateUrl: './projects-orderslip.component.html',
  styleUrls: ['./projects-orderslip.component.scss']
})
export class ProjectsOrderslipComponent implements OnInit {

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.setPageDetails();
  }

  private setPageDetails() {
    this.appService.setPageTitle('Opdrachtbon');
    this.appService.setNavKey('orderslip');
  }
}
