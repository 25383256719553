<header class="page-header">
    <div class="row">
        <div class="col-8">
            <h1>Planning</h1>
        </div>
        <div class="col-4 alignRight">
            <button class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-8">
            <!-- <h3>Week {{currentWeek}}, {{currentYear}}</h3> -->
        </div>
        <div class="col-4 d-flex d-lg-block flex-row-reverse">
            <span class="btn btn-light float-end" style="background-color:#EEEEEE;" (click)="next()"><i
                    class="bi bi-arrow-right"></i></span>
            <span class="btn btn-light float-end" style="background-color:#EEEEEE;" (click)="prev()"><i
                    class="bi bi-arrow-left"></i></span>
        </div>
    </div>

    <div class="row mt-3 mb-3" id="weekContainer">
        <div class="col-6 col-lg mb-2 mb-lg-0 weekCard shadow-small"
            *ngFor="let day of calendar | keyvalue | slice:0:5">
            <div class="weekHead">
                {{getDutchDateFormat(day.key)}}
                <small>{{getDutchWeekday(day.key)}}</small>
            </div>
            <div class="weekBody">
                <div class="weekEntry" *ngFor="let entry of toArr(day.value)"
                    [routerLink]="['/projects/detail/'+entry.id +'']" style="cursor:pointer;">
                    <div class="wrapper">
                        <span [ngStyle]="{'background-color': entry.groupColor}">{{entry.group}}</span>
                        <p>{{entry.name}}</p>
                    </div>
                    <small>fase {{entry.phase}}</small>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3 mb-3" id="weekContainer">
        <div class="col-6 col-lg mb-2 mb-lg-0 weekCard shadow-small"
            *ngFor="let day of calendar | keyvalue | slice:5:10">
            <div class="weekHead">
                {{getDutchDateFormat(day.key)}}
                <small>{{getDutchWeekday(day.key)}}</small>
            </div>
            <div class="weekBody">
                <div class="weekEntry" *ngFor="let entry of toArr(day.value)"
                    [routerLink]="['/projects/detail/'+entry.id +'']" style="cursor:pointer;">
                    <div class="wrapper">
                        <span [ngStyle]="{'background-color': entry.groupColor}">{{entry.group}}</span>
                        <p>{{entry.name}}</p>
                    </div>
                    <small>fase {{entry.phase}}</small>
                </div>
            </div>
        </div>
    </div>
</header>