<div *ngIf="items && items['items']">
    <div class="text-center py-4" *ngIf="type == 'COSTS' && items['items'].length < 1">
        <p>
            Nog geen kosten bekend. Maak een keuze uit onderstaande opties.
        </p>
        <button (click)="addRow('INVENTORY')" class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button> 
        <span class="d-inline-block px-3">of</span> 
        <span class="d-inline-block mt-3" *ngIf="project">
            <button (click)="convertQuote()" class="btn btn-dark with-icon">Kopie&euml;er offerte<i class="bi bi-arrow-right"></i></button>
        </span>
    </div>

    <section class="table-content project-calculation-table" *ngIf="type == 'QUOTE' || items['items'].length > 0">
        <table class="table mb-0">
            <thead class="thead-dark">
                <tr>
                    <th>
                        Kostenpost
                    </th>
                    <th>
                        Beschrijving
                    </th>
                    <th>
                        Aantal
                    </th>
                    <th>
                        Eenheid
                    </th>
                    <th>
                        Van 
                    </th>
                    <th>
                        Tot 
                    </th>
                    <th>
                        Prijs 
                    </th>
                    <th>
                        Totaal
                    </th>
                    <th>
                        
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let category of categories">
                    <tr>
                        <td colspan="2">
                            <strong>{{category.name}}</strong>
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td class="text-end" colspan="2">
                            <span (click)="addRow(category['value'])">
                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                            </span>
                        </td>
                    </tr>    
                    <ng-container *ngFor="let item of items['items']">
                    <tr *ngIf="item['category'] === category['value']">
                        <td>
                            
                        </td>
                        <td>
                            <span style="display:block;position:relative;">
                                <input type="text" classs="form-control" style="width:100%;" [(ngModel)]="item.description" placeholder="Beschrijving" (focus)="showNameList($event, item)" (blur)="hideNameList($event, item)">
                                <div class="row-suggestion-list" *ngIf="activeRowNameId == item['id']">
                                    <div class="select-suggestion" (click)="selectName(item, searchName)" *ngFor="let searchName of searchNames(item)">
                                        {{searchName['name']}} <small>(&euro; {{searchName['price']}})</small>
                                    </div>
                                </div>
                            </span>
                        </td>
                        <td width="110">
                            <input type="text" (blur)="update(item)" (focus)="selectAll($event)" classs="form-control" placeholder="Aantal" [(ngModel)]="item.amount" style="width:90px;">
                        </td>
                        <td width="100">
                            <span style="display:block;position:relative;">
                                <input type="text" id="calculation-unit-{{item['id']}}" [(ngModel)]="item['unit']" (focus)="showUnitList($event, item)" (blur)="hideUnitList($event, item)" class="form-control" style="width:90px;">
                                <div class="row-suggestion-list" *ngIf="activeRowTypeId == item['id']">
                                    <div class="select-suggestion" (click)="selectUnit(item, searchUnit)" placeholder="Stuks"  *ngFor="let searchUnit of searchUnits(item)">{{searchUnit}}</div>
                                </div>
                            </span>
                        </td>
                        <td width="140">
                            <input type="date" (blur)="update(item)" classs="form-control" placeholder="Van" [(ngModel)]="item.startAt">
                        </td>
                        <td width="140"> 
                            <input type="date" (blur)="update(item)" classs="form-control" placeholder="Tot" [(ngModel)]="item.endAt">
                        </td>
                        <td width="140">
                            <input type="text" (blur)="update(item)" (focus)="selectAll($event)" classs="form-control" placeholder="Prijs" style="width:120px;" [(ngModel)]="item.price">
                        </td>
                        <td width="140">
                            <input type="text" classs="form-control price-info" disabled style="width:120px;" [(ngModel)]="item.total" style="border-color:transparent ! important;background: transparent ! important;">
                        </td>
                        <td class="text-end pt-4">
                            <span (click)="confirmDelete(item)" class="me-3 row-icon delete2-icon">
                                <i class="bi bi-trash-fill"></i>
                            </span>

                            <span (click)="addRow(category['value'])" style="opacity:0.6">
                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                            </span>
                        </td>
                    </tr>
                    </ng-container>   
                </ng-container>       
            </tbody>
        </table>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </section>
</div>