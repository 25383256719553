import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './pages/index/index.component';

import { OrganisationsIndexComponent } from './pages/organisations-index/organisations-index.component';
import { OrganisationsFormComponent } from './pages/organisations-form/organisations-form.component';
import { ProjectsDetailComponent } from './pages/projects-detail/projects-detail.component';

import { UsersIndexComponent } from './pages/users-index/users-index.component';
import { UserGroupsIndexComponent } from './pages/user-groups-index/user-groups-index.component';

import { AuthGuard } from './services/core/authguard.service';

import { LoginComponent } from './pages/login/login.component';
import { NotauthorizedComponent } from './pages/notauthorized/notauthorized.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ProjectsIndexComponent } from './pages/projects-index/projects-index.component';
import { ArchiveIndexComponent } from './pages/archive-index/archive-index.component';
import { QualityComponent } from './pages/quality/quality.component';
import { HoursComponent } from './pages/hours/hours.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SetupPasswordComponent } from './pages/setup-password/setup-password.component';

import { ProjectsOrderslipComponent } from './pages/projects-orderslip/projects-orderslip.component';
import { NotesComponent } from './pages/notes/notes.component';
import { PrintComponent } from './pages/print-index/print-index.component';
import { InventoryComponent } from './pages/inventory/inventory.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full', canActivate: [AuthGuard] },
  
  { path: 'index', component: IndexComponent, canActivate: [AuthGuard] },

  { path: 'projects', component: ProjectsIndexComponent, canActivate: [AuthGuard] },
  { path: 'projects/detail/:id', component: ProjectsDetailComponent, canActivate: [AuthGuard] },
  { path: 'projects/workorders/:id', component: ProjectsOrderslipComponent, canActivate: [AuthGuard] },
  { path: 'projects/workorders', component: ProjectsOrderslipComponent, canActivate: [AuthGuard] },
  { path: 'quality', component: QualityComponent, canActivate: [AuthGuard] },
  { path: 'hours', component: HoursComponent, canActivate: [AuthGuard] },
  { path: 'archive', component: ArchiveIndexComponent, canActivate: [AuthGuard] },
  
  { path: 'organisations', component: OrganisationsIndexComponent, canActivate: [AuthGuard] },
  { path: 'organisations/form/:id', component: OrganisationsFormComponent, canActivate: [AuthGuard] },
  
  { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },

  { path: 'users', component: UsersIndexComponent, canActivate: [AuthGuard] },
  { path: 'usergroups', component: UserGroupsIndexComponent, canActivate: [AuthGuard] },
  
  { path: 'notauthorized', component: NotauthorizedComponent },
  { path: 'settings', component: SettingsComponent },
  
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'login/:hash/:email', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgotten', component: ForgotPasswordComponent },
  { path: 'password/:hash/:email', component: SetupPasswordComponent },

  { path: 'notes', component: NotesComponent },
  { path: 'print', component: PrintComponent },
  { path: 'print/:id', component: PrintComponent },
  { path: 'inventory', component: InventoryComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
