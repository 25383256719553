import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ProjectFilesComponent } from '../project-files/project-files.component';
import { ProjectMapComponent } from '../project-map/project-map.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-project-workorders',
  templateUrl: './project-workorders.component.html',
  styleUrls: ['./project-workorders.component.scss']
})
export class ProjectWorkordersComponent implements OnInit {
  @Input() project;
  items;
  collapse = {};
  collapsedSigns = {};

  constructor(
    private api: ApiConnectionService,
    private modalService: NgbModal,
    private app: AppService
  ) { }

  ngOnInit(): void {
    this.fetchItems();
  }

  toggleSigns(id) {
    this.collapsedSigns[id] = !this.collapsedSigns[id];
  }

  fetchItems() {
    let self = this;
    let url = 'workOrders/all';
    if (this.project && this.project['id']) {
      url = 'workOrders/project/' + this.project['id'] + '';
    }
    this.api.read(url).then(function (response) {
      self.items = response['items'];


      for (let i = 0; i < self.items.length; i++) {
        const printSigns = self.items[i].printSigns;



        let orderedList = {};
        // Sort the printSigns array based on inventoryId

        // Sort the printSigns based on inventoryId
        printSigns.sort((a, b) => a.inventoryId.localeCompare(b.inventoryId));

        // Loop through the sorted printSigns and populate the index object
        for (const sign of printSigns) {
          const { inventoryId } = sign;

          // Check if the inventoryId already exists in the index object
          if (inventoryId in orderedList) {
            // If it exists, push the sign to the existing array
            orderedList[inventoryId].push(sign);
          } else {
            // If it doesn't exist, create a new array with the sign
            orderedList[inventoryId] = [sign];

            self.collapsedSigns[inventoryId + "-p" + self.items[i]['phase']['number']] = false;
          }
        }

        self.items[i].printSigns = orderedList;
      }
      //loop through items, group items in new object based on inventoryId. Make table collapsable based on inventoryId


      if (Object.keys(self.collapse).length < 1) {
        for (let i = 0; i < self.items.length; i++) {
          if (self.items[i]['isPrintFinished'] === false) {
            self.collapse[self.items[i]['id']] = true;
            break;
          }
        }
      }
    });
  }

  updateSignByType(inventoryId, phaseId, index) {
    let self = this;

    for (let i = 0; i < phaseId['value'].length; i++) {
      const sel = phaseId['value'][i];

      sel['status' + index + ''] = 'READY';

      console.log(sel)

      self.app.setLoaderStatus(true);
      this.api
        .save("phases/updateSign/" + sel['id'] + "", sel)
        .then((response) => {
          self.app.setLoaderStatus(false);
          self.app.setSystemMessageLoading(true);
          self.app.setSystemMessageText("Succesvol toegevoegd");
          self.app.setSystemMessageType("SUCCESS");
          // self.fetchItems();
        })
        .catch(function (error) {
          self.app.setLoaderStatus(false);
        });
    }
  }

  updatePhaseSigns(phase, index) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .read("phases/updateSignStatus/" + phase.id + "/" + index + "")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Borden gereed gemeld");
        self.app.setSystemMessageType("SUCCESS");
        setTimeout(function () {
          // self.fetchItems();
        }, 5000);
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  changeSignStatus(sign, index, status) {
    sign['status' + index + ''] = status;

    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .save("phases/updateSign/" + sign['id'] + "", sign)
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Succesvol toegevoegd");
        self.app.setSystemMessageType("SUCCESS");
        // self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  openVerkeersplan(project) {
    let self = this;
    const filterModal = this.modalService.open(ProjectMapComponent, {
      backdrop: 'static',
      size: 'lg'
    });

    filterModal.componentInstance.item = project;
    filterModal.componentInstance.modal = true;

    filterModal.result.then((result) => {
    })
      .catch((error) => {
      });
  }

  changeCollapse(key, value) {
    this.collapse[key] = value;
  }
}
