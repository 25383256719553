<header class="page-header">
	<div class="row">
		<div class="col-12 col-xl-4">
			<h1>Urenregistratie</h1>
			<h6>Urenregistratie / overzicht</h6>
		</div>
		<div class="col-12 col-xl-8 text-end pt-3">
            <div class="dateSelect">
                <select name="year" (change)="getHours()" [(ngModel)]="startYear" class="form-control" style="display:inline-block;width:100px;margin-right:5px;">
                    <option value="{{year}}" *ngFor="let year of years">
                    {{year}}
                    </option>
                </select>
                <select name="week" (change)="getHours()" [(ngModel)]="startWeek" class="form-control" style="display:inline-block;width:160px;">
                    <option value="{{week}}" *ngFor="let week of weeks">
                        Week {{week}} - ({{ dateString(week) }})
                    </option>
                </select>
                <div class="d-block d-md-inline-block px-3 py-2">
                    tot
                </div>
                <select name="year" (change)="getHours()" [(ngModel)]="endYear" class="form-control" style="display:inline-block;width:100px;margin-right:5px;">
                    <option value="{{year}}" *ngFor="let year of years">
                    {{year}}
                    </option>
                </select>
                <select name="week" (change)="getHours()" [(ngModel)]="endWeek" class="form-control" style="display:inline-block;width:160px;">
                    <option value="{{week}}" *ngFor="let week of weeks">
                        Week {{week}} - ({{ dateString(week) }})
                    </option>
                </select>
            </div>
		</div>
	</div>
</header>
<section class="table-grid" *ngIf="projectHours && projectHours.length > 0">
  <section class="table-content">
      <table class="table table-vertical-align" style="margin-bottom:0;">
          <thead class="thead-dark">
              <tr>
                  <th width="140">Week</th>
                  <th width="140" class="sticky">Werknemer</th>
                  <th width="100">Ma</th>
                  <th width="100">Di</th>
                  <th width="100">Wo</th>
                  <th width="100">Do</th>
                  <th width="100">Vr</th>
                  <th width="100">Za</th>
                  <th width="100">Zo</th>
                  <th width="100">Totaal</th>
                  <th width="110">&nbsp;</th>
              </tr>
          </thead>
          <tbody>
              <ng-container *ngFor="let hour of projectHours" >
              <tr class="hour-row">
                  <td>
                      <i (click)="setExpended('')" class="bi bi-chevron-up icon-pointer me-3 pt-1" *ngIf="expendedKey == hour['period']"></i> 
                      <i (click)="setExpended(hour['period'])" class="bi bi-chevron-down icon-pointer me-3 pt-1" *ngIf="expendedKey !== hour['period']"></i> 
                      <b>Week {{hour['name']}}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><b>{{hour['total']}} uur</b></td>
                  <td>
                  </td>
              </tr>
              <ng-container *ngFor="let userHour of hour['hours']; let index = index; let last = last;">
                <tr class="user-hour-row" [ngClass]="{'d-none': expendedKey !== hour['period'],  last: last}">
                    <td></td>
                    <th>
                            {{userHour['userName']}} 
                            <span class="expend" *ngIf="userHour['userId'] == userDetailId && userHour['period'] == userDetailPeriod" style="cursor:pointer;" (click)="clearUserDetailId()"><i class="bi bi-chevron-up pe-2"></i></span>
                            <span class="expend" *ngIf="userHour['userId'] != userDetailId || userHour['period'] != userDetailPeriod" style="cursor:pointer;" (click)="setUserDetailId(userHour['userId'], userHour['period'])"><i class="bi bi-chevron-down pe-2"></i></span>
                    </th>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-monday" (focus)="selectAll($event)"    readonly  [(ngModel)]="userHour['monday']"></td>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-tuesday" (focus)="selectAll($event)"   readonly   [(ngModel)]="userHour['tuesday']"></td>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-wednesday" (focus)="selectAll($event)" readonly     [(ngModel)]="userHour['wednesday']"></td>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-thursday" (focus)="selectAll($event)"  readonly     [(ngModel)]="userHour['thursday']"></td>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-friday" (focus)="selectAll($event)"    readonly     [(ngModel)]="userHour['friday']"></td>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-saturday" (focus)="selectAll($event)"  readonly    [(ngModel)]="userHour['saturday']"></td>
                    <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-sunday" (focus)="selectAll($event)"    readonly    [(ngModel)]="userHour['sunday']"></td>
                    <td><b>{{userHour['total']}} uur</b></td>
                    <td>                      
                    </td>
                </tr>
                <ng-container *ngIf="userHour['userId'] == userDetailId && userHour['period'] == userDetailPeriod" >
                    <tr *ngFor="let userDetail of userDetails; let index = index; let last = last;" class="user-hour-row">
                        <td></td>
                        <th class="text-muted">
                            <div class="ps-5"><a href="javacript:;" [routerLink]="'/projects/detail/'+userDetail['projectId']+''">{{userDetail['projectName']}}</a></div>
                        </th>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-monday" (focus)="selectAll($event)"    readonly  [(ngModel)]="userDetail['monday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-tuesday" (focus)="selectAll($event)"   readonly   [(ngModel)]="userDetail['tuesday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-wednesday" (focus)="selectAll($event)" readonly     [(ngModel)]="userDetail['wednesday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-thursday" (focus)="selectAll($event)"  readonly     [(ngModel)]="userDetail['thursday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-friday" (focus)="selectAll($event)"    readonly     [(ngModel)]="userDetail['friday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-saturday" (focus)="selectAll($event)"  readonly    [(ngModel)]="userDetail['saturday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-sunday" (focus)="selectAll($event)"    readonly    [(ngModel)]="userDetail['sunday']"></td>
                        <td><b>{{userDetail['total']}} uur</b></td>
                        <td>                      
                        </td>
                    </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
          </tbody>
      </table>
  </section>
</section> 