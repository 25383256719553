<div class="row">
    <div class="col-12" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px;">
        <small>Selecteer uit de onderstaande opties</small>
    </div>
</div>
<div class="row" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
    <div class="col-12">
        <input type="text" value="" class="form-control" [(ngModel)]="keyword" (keyup)="searchSigns()" (change)="searchSigns()" placeholder="Zoeken">
    </div>
</div>
<section class="sign-list">
    <ng-container *ngFor="let sign of signs">
        <div class="row row-signs" *ngIf="sign['hide'] != true">
            <div *ngIf="type == 'phase'" class="col-1" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
                <i class="bi bi-square-fill check-inactive check-icon ms-1" (click)="changeQuantity(sign, 1)" *ngIf="!sign['quantity'] || sign['quantity'] < 1"></i>
                <i class="bi bi-check-square-fill check-icon ms-1" (click)="changeQuantity(sign, 0)" *ngIf="sign['quantity'] > 0"></i>
            </div>
            <div class="col-4" style="border-bottom:1px solid #EEEEEE;padding-top:10px;padding-bottom:10px;">
                <img [src]="sign['image']" class="sign-image"> 
                {{sign['name']}}
            </div>
            <div class="col-2  ps-1" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">        
                <span [innerHTML]="sign['printableIcon']"></span>
            </div>
            <div class="col-2 ps-1" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">        
                <span class="text-muted">&euro; {{sign['buyPrice']}}</span>
            </div>
            <div class="col-2 ps-1" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">        
                <span class="text-muted">&euro; {{sign['sellPrice']}}</span>
            </div>
            <div *ngIf="type != 'phase'" class="col-2 py-1 ps-1" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
                <input (change)="handleActiveSignIds()" type="number" class="form-control" [(ngModel)]="sign['quantity']" value="0" style="width:100px;">
            </div>
        </div>
    </ng-container>
</section>