import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';

import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { ProjectInfoFilesComponent } from 'src/app/components/projects/project-info-files/project-info-files.component';
import { QuoteStatusFormComponent } from 'src/app/components/projects/quote-status-form/quote-status-form.component';
import { QuoteAcceptedFormComponent } from 'src/app/components/projects/quote-accepted-form/quote-accepted-form.component';

@Component({
  selector: 'app-projects-index',
  templateUrl: './projects-index.component.html',
  styleUrls: ['./projects-index.component.scss']
})
export class ProjectsIndexComponent implements OnInit {
  link = 'projects';
  refresh = 0;
  editPath = 'projects/detail/';
  mapping = [
	{key: 'number', label: 'ID', width: '140px', sort: true},
	{key: 'name', label: 'Project', sort: true},
	{key: 'organisationName', label: 'Klant', sort: true },
	{key: 'siteManagerName', label: 'Uitvoerder', sort: true },
	{key: 'requestedAtText', label: 'Aanvraagdatum', sort: true },
	{key: 'startAtText', label: 'Aanvangdatum', sort: true },
	{key: 'statusLabel', label: 'Status', sort: true  }
  ];

  constructor(
  	public apiService: ApiConnectionService,
	  private appService: AppService,
  	private modalService: NgbModal,
  	private ngxLoader: NgxUiLoaderService
  ) { }

  openQuoteStatus (event, item) 
  {
	event.stopPropagation();
	let self = this;
	
	const filterModal = this.modalService.open(QuoteStatusFormComponent, {
	});
	
	filterModal.componentInstance.item = item;
	filterModal.result.then((result) => {
		self.refresh = Math.random();
	})
	.catch((error) => {

	});
	
  }

  openAcceptedStatus (event, item)
  {
	event.stopPropagation();
	let self = this;
	
	const filterModal = this.modalService.open(QuoteAcceptedFormComponent, {
	});
	
	filterModal.componentInstance.item = item;
	filterModal.result.then((result) => {
		self.refresh = Math.random();
	})
	.catch((error) => {

	});
	
  }

  ngOnInit(): void {
	  this.appService.setPageTitle('Projecten');
	  this.appService.setNavKey('projects');
  }

  openFileForm (data)
  {
	let self = this;
	
	const filterModal = this.modalService.open(ProjectInfoFilesComponent, {
	  windowClass: 'modal-right',
	  size: 'lg'
	});
	
	filterModal.componentInstance.id = data['id'];
	filterModal.result.then((result) => {
		self.refresh = Math.random();
	})
	.catch((error) => {

	});
  }
  
  openForm (data)
  {
	  let self = this;
	
	  const filterModal = this.modalService.open(ProjectInfoFormComponent, {
		windowClass: 'modal-right',
		size: 'lg'
	  });
	  
	  filterModal.componentInstance.id = data['id'];
	  filterModal.componentInstance.organisationId = data['organisationId'];
	  filterModal.componentInstance.roleKey = data['role'];
	  filterModal.result.then((result) => {
		self.refresh = Math.random();
		if (result !== false)
		{
			self.openFileForm(result);
		}
	  })
	  .catch((error) => {
	  });
  }
}