import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-project-permit-status',
  templateUrl: './project-permit-status.component.html',
  styleUrls: ['./project-permit-status.component.scss']
})
export class ProjectPermitStatusComponent implements OnInit {
	@Input() public project;
	@Input() public type;
	@Input() public reload;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

  public loaderOverlay = false;
  public statusOptions;
  public selectedStatus = 'NVT';
  public currentTab = 'info';

  public currentUser;

	constructor(
  		public activeModal: NgbActiveModal,
      public appService: AppService,
      public authenticationService: AuthenticationService,
      public api: ApiConnectionService,
      public config: ConfigService
  ) { 
    this.statusOptions = this.config.get('permit_status_enum');

    let self = this;

		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
		  if (Object.keys(data).length) {
			  self.currentUser = data;
		  } else {
			  self.currentUser = false;
		  }
		});
  }

  ngOnInit(): void {
    this.selectedStatus = this.getCurrentActiveStatus();
    if (this.selectedStatus === 'RECEIVED' || this.type === 'QUOTE') {
      this.setTab('files');
    } else {
      this.setTab('info');
    }
  }

  ngOnChanges(changes: any) {
    this.selectedStatus = this.getCurrentActiveStatus();
    if (this.selectedStatus === 'RECEIVED' || this.type === 'QUOTE') {
      this.setTab('files');
    } else {
      this.setTab('info');
    }
  }

  setSelectedStatus (selectedStatus)
  {
    this.selectedStatus = selectedStatus;
    this.confirm();
  }

  getCurrentActiveStatus ()
  {
    let type = this.type.toLocaleLowerCase();
    return this.project[type + 'Status'];
  }

  getCurrentActiveStatusText ()
  {
    let type = this.type.toLocaleLowerCase();
    return this.project[type + 'StatusText'];
  }

  setTab(tab) {
    this.currentTab = tab;
  }
  
	confirm () {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);

    let type = this.type.toLocaleLowerCase();
    let target = type + 'Status';

    self.appService.setSystemMessageText('Status ' + self.type.toLocaleLowerCase() + ' gewijzigd');

		return this.api.save('projects/changePermitStatus', {
      projectId: self.project['id'],
      target: target,
      status: this.selectedStatus
    }).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

      if (self.selectedStatus == 'RECEIVED') {
        self.setTab('files');
      } else {
		    self.activeModal.close(true);
      }

		}).catch (function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

		  self.activeModal.close(true);
		});
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
}
