import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectAddSignModalComponent } from '../project-add-sign-modal/project-add-sign-modal.component';
import { ProjectPhaseMutateComponent } from '../project-phase-mutate/project-phase-mutate.component';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AppService } from 'src/app/services/app.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-project-phases',
  templateUrl: './project-phases.component.html',
  styleUrls: ['./project-phases.component.scss']
})
export class ProjectPhasesComponent implements OnInit {
  @Input() public project;
  items = [];
  phaseCollapse = {};

  subSignCollapse = {};

  constructor(
    private app: AppService,
    public modalService: NgbModal,
    public api: ApiConnectionService
  ) {

  }

  ngOnInit(): void {
    this.fetchItems();
  }

  fetchItems() {
    let self = this;
    this.api.read('phases/project/' + self['project']['id'] + '').then(function (response) {
      self.items = response['items'];

      for (let i = 0; i < self.items.length; i++) {
        const signs = self.items[i]['phaseSigns'];
        for (let i2 = 0; i2 < signs.length; i2++) {
          self.subSignCollapse[i + "-" + signs[i2]['inventoryId']] = false;
        }
      }

      console.log(self.items);
      if (Object.keys(self.phaseCollapse).length < 1) {
        for (let i = 0; i < self.items.length; i++) {
          if (self.items[i]['isFinished'] === false) {
            self.phaseCollapse[self.items[i]['id']] = true;
            break;
          }
        }
      }
    });
  }

  updateSignByType(phaseId, index, state) {
    let self = this;

    console.log(phaseId)

    for (let i = 0; i < phaseId.length; i++) {
      const sel = phaseId[i];

      sel['status' + index + ''] = state;

      console.log(sel)

      self.app.setLoaderStatus(true);
      this.api
        .save("phases/updateSign/" + sel['id'] + "", sel)
        .then((response) => {
          self.app.setLoaderStatus(false);
          self.app.setSystemMessageLoading(true);
          self.app.setSystemMessageText("Succesvol toegevoegd");
          self.app.setSystemMessageType("SUCCESS");
          self.fetchItems();
        })
        .catch(function (error) {
          self.app.setLoaderStatus(false);
        });
    }
  }

  toggleCollapse(id) {
    this.subSignCollapse[id] = !this.subSignCollapse[id];
  }

  changeCollapse(key, value) {
    this.phaseCollapse[key] = value;
    console.dir(this.phaseCollapse);
  }

  addSign(phase) {
    let self = this;

    const modal = this.modalService.open(ProjectAddSignModalComponent, {
      size: 'lg'
    });
    modal.componentInstance.project = self.project;
    modal.componentInstance.phase = phase;
    modal.result.then((result) => {
      if (result) {
        self.fetchItems();
      }
    });
  }

  changeSignStatus(sign, index, status) {
    sign['status' + index + ''] = status;

    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .save("phases/updateSign/" + sign['id'] + "", sign)
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Succesvol toegevoegd");
        self.app.setSystemMessageType("SUCCESS");
        self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  editPhase(phase) {
    this.addPhase(phase);
  }

  confirmDelete(item) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message =
      "Weet je zeker dat je dit bord wilt verwijderen?";
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(item);
      }
    });
  }

  delete(item) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .delete("phases/deleteSign/" + item.id + "")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Bord verwijderd");
        self.app.setSystemMessageType("DANGER");
        self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  updatePhaseSigns(phase, index) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .read("phases/updateSignStatus/" + phase.id + "/" + index + "")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Borden gereed gemeld");
        self.app.setSystemMessageType("SUCCESS");
        self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  addPhase(phase) {
    let self = this;

    const modal = this.modalService.open(ProjectPhaseMutateComponent, {
      size: 'lg'
    });
    modal.componentInstance.project = self.project;
    modal.componentInstance.phase = phase;
    modal.result.then((result) => {
      self.fetchItems();
      if (result) {

      }
    });
  }
}
