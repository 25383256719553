import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService }  from '../../services/core/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	submitted;
	loginForm;
	error : boolean = false;
	loading = false;

	public currentUser : any;
	
  	constructor(
  		private authenticateService: AuthenticationService,
		private activateRouter: ActivatedRoute,
    	private router: Router,
  		private formBuilder: FormBuilder
  	) { 
		let self = this;

		let hash = this.activateRouter.snapshot.paramMap.get('hash');
		let email = this.activateRouter.snapshot.paramMap.get('email');

		console.log(hash);
		console.log(email);

		this.authenticateService.getCurrentUser().then(function (data) {
			
			self.authenticateService.data.subscribe(data => {
				if (Object.keys(data).length) {
					self.currentUser = data;
				} else {
					self.currentUser = false;
				}
			});
		  }).catch (function (data) {
			self.authenticateService.data.subscribe(data => {
				if (Object.keys(data).length) {
					self.currentUser = data;
				} else {
					self.currentUser = false;
				}
			});
		  });
  	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(3)]]
		});
	}
	
	get f() { return this.loginForm.controls; }
	
	onSubmit() {
		this.submitted = true;
		this.error = false;
		
		let self = this;

		if (this.loginForm.invalid) {
			return;
		}
		if(this.submitted)
		{
			self.loading = true;
			
			let email = this.loginForm.controls.email.value;
			let password = this.loginForm.controls.password.value;
			password = encodeURIComponent(password);			
			
			this.authenticateService.authenticate(email, password).then(function (response) {
				self.loading = false;
				self.authenticateService.getCurrentUser();		
				self.router.navigate(['index']);
			}).catch(function (error) {
				self.loading = false;
				self.error = true;
			});
		}
	
	}
}
