<div class="modal-header" *ngIf="modal == true">
    <h5 class="modal-title">Borden toevoegen asdasdasd</h5>
    <button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
</div>
<div class="modal-body" [ngClass]="{
    'modal-body' : modal == true
}"> 
    <div class="row">
        <div class="col-6">
            <app-project-files [project]="item" (reloadAction)="fetchItem()" [reload]="refresh"  type="quick" target="VERKEERSPLAN"></app-project-files>
        </div>
        <div class="col-6">
            
        </div>
    </div>

    <div *ngIf="file && file['imageLink']" class="image-preview-container" (reloadAction)="fetchItem()" [innerHTML]="imageHtml">


        
    </div>
    <br/><br/><br/>
    <br/><br/><br/>
</div>
<div class="modal-footer" *ngIf="modal == true">
    <button (click)="cancel()" type="submit" class="btn btn btn-link" style="text-decoration:none ! important;color:#000000;">Annuleren</button>
    <button (click)="confirm()" type="submit" class="btn btn-success">Toevoegen</button>
</div>