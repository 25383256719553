import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';

import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { ProjectInfoFilesComponent } from 'src/app/components/projects/project-info-files/project-info-files.component';

@Component({
  selector: 'calendar-index',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  link = 'calendar';
  refresh = 0;
  keyword = '';
  editPath = 'calendar';
  active;
  ganttMonth = "2023-02-13";

  startWeek;
  endWeek;

  startDate;
  endDate;

  loading = false;

  calendar = {};

  ganttFourWeekDates = [];
  ganttHeadList = [];
  ganttDayList = [];

  projects;
  pendingProjects;
  projectStatus = ['QUOTE_ACCEPTED', 'PLAN', 'IN_PROGRESS'];
  userGroups;
  ganttData;

  numberOfWeeks = 6;

  today;

  public calendarSwitch = false;

  constructor(
    public apiService: ApiConnectionService,
    private appService: AppService,
    private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.setPageDetails();
    // this.fetchGanttData(this.ganttMonth);
  }

  public switchCalendar() {
    this.calendarSwitch = !this.calendarSwitch;
  }

  toArr(obj) {
    return Object.values(obj);
  }

  getDutchDateFormat(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  getDutchWeekday(dateString) {
    const weekdays = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const date = new Date(dateString);
    const weekdayIndex = date.getDay();
    const dutchWeekday = weekdays[weekdayIndex];
    return dutchWeekday;
  }

  public fetchGanttData(e) {

    if (e != null) {
      e = new Date(e);
    }

    this.ganttFourWeekDates = this.getNextFourWeekDates(e);
    this.getNextFourWeeks(e);
    this.ganttDayList = this.getNextFourWeekDays(e);

    let dt1 = new Date(this.ganttMonth);

    let monthStr = (dt1.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }

    this.startWeek = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dt1.getDate();
    this.startDate = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + (dt1.getDate() < 10 ? '0' : '') + dt1.getDate();

    let dt2 = new Date(this.ganttMonth);
    dt2.setDate(dt2.getDate() + 6);

    monthStr = (dt2.getMonth() + 1);
    mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }

    this.endWeek = dt2.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dt2.getDate();
    this.endDate = dt2.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + (dt2.getDate() < 10 ? '0' : '') + dt2.getDate();

    localStorage.setItem("calendarStartDate", this.startDate);
    localStorage.setItem("calendarEndDate", this.endDate);
  }

  ngOnInit(): void {
    this.getProjects();
    this.currentDate();
  }

  getProjects() {
    let self = this;

    self.appService.setLoaderStatus(true);

    this.apiService.read("calendar").then((data) => {
      self.calendar = data;
      self.appService.setLoaderStatus(false);
    });
  }

  public getNextFourWeeks(startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const result = [];

    for (let i = 0; i < this.numberOfWeeks; i++) {
      const endDate = new Date(startDate.getTime() + (oneDay * 6)); // add 6 days to get end date
      const startMonth = startDate.toLocaleString('default', { month: 'short' }); // get short month name
      const endMonth = endDate.toLocaleString('default', { month: 'short' });
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      const weekString = `${startDay} ${startMonth} - ${endDay} ${endMonth}`; // create week string
      result.push(weekString);
      startDate = new Date(endDate.getTime() + oneDay); // set start date to the day after end date
    }

    this.ganttHeadList = result;
  }

  public getNextFourWeekDays(startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const result = [];

    for (let i = 0; i < (this.numberOfWeeks * 7); i++) {
      const currentDate = new Date(startDate.getTime() + (oneDay * i)); // add i days to get current date
      result.push(currentDate.getDate());
    }

    return result;
  }

  public getNextFourWeekDates(startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const result = [];

    for (let i = 0; i < (this.numberOfWeeks * 7); i++) {
      const currentDate = new Date(startDate.getTime() + (oneDay * i)); // add i days to get current date
      result.push(currentDate.toISOString().slice(0, 10));
    }

    return result;
  }

  addedEntries = [];
  addedEntriesDate = [];

  public isDateBetween(dateToCheck, startDate, endDate, id) {
    const checkDate = new Date(dateToCheck);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return checkDate >= start && checkDate <= end;
  }

  private setPageDetails() {
    this.appService.setPageTitle('Planning');
    this.appService.setNavKey('calendar');
  }

  public getDayOfDate(date) {
    const day = new Date(date).getDate();
    return day;
  }

  private dateDiffInDays(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);

    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const diffInTime = date2.getTime() - date1.getTime(); // difference in milliseconds
    const diffInDays = Math.floor(diffInTime / oneDay); // convert to days and round down
    return diffInDays;
  }

  currentDate() {
    var curr = new Date;
    var first = curr.getDate() - curr.getDay() + 1;
    var last = first + 6;

    let calendarStartDate = localStorage.getItem("calendarStartDate");
    let calendarEndDate = localStorage.getItem("calendarEndDate");

    if (calendarStartDate) {
      this.ganttMonth = calendarStartDate;
      var firstday = new Date(this.ganttMonth);
      this.fetchGanttData(firstday);
      return;
    }

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last)).toUTCString();

    let monthStr = (firstday.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }
    this.ganttMonth = firstday.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + (firstday.getDate() < 10 ? '0' : '') + firstday.getDate();
    this.fetchGanttData(firstday);

    return firstday;
  }

  next() {
    let dt1 = new Date(this.ganttMonth);
    dt1.setDate((dt1.getDate() + (1 * 7)));

    let monthStr = (dt1.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }


    let day = dt1.getDate();
    let dayStr = day.toString();
    if (dt1.getDate() < 10) {
      dayStr = '0' + dayStr;
    }

    this.ganttMonth = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dayStr;
    this.fetchGanttData(dt1);
    this.getProjects();
  }

  prev() {
    let dt1 = new Date(this.ganttMonth);
    dt1.setDate((dt1.getDate() - (1 * 7)));

    let monthStr = (dt1.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }

    let day = dt1.getDate();
    let dayStr = day.toString();
    if (dt1.getDate() < 10) {
      dayStr = '0' + dayStr;
    }

    this.ganttMonth = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dayStr;
    this.fetchGanttData(dt1);
    this.getProjects();
  }

  dateString(week, year) {
    var curr = new Date; // get current date

    let y = year;
    var d = new Date("Jan 01, " + y + " 01:00:00");
    var dayMs = (24 * 60 * 60 * 1000);
    var offSetTimeStart = dayMs * (d.getDay() - 1);
    var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
    var n1 = new Date(w);
    var n2 = new Date(w + 518400000);

    let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
    let month = n1.getMonth();
    month = (month + 1);
    let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

    var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
    return firstday;
  }
}