import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	constructor(
  		public activeModal: NgbActiveModal
  	) { }

	ngOnInit(): void {
		
	}
  
	confirm () {
		this.activeModal.close(true);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
}