import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-send-quote',
  templateUrl: './send-quote.component.html',
  styleUrls: ['./send-quote.component.scss']
})
export class SendQuoteComponent implements OnInit {
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Input() public project;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
  showSuccess = false;
	content;
	loaderOverlay = false;
  files = [];
  photos = [];
  previewUrl = '';
  photoTabExtended = false;
  data;

	constructor(
  		public activeModal: NgbActiveModal,
		  public appService: AppService,
      public config: ConfigService,
      public api: ApiConnectionService,
      public sanitizer: DomSanitizer
  	) { }


  ngOnInit(): void {
    this.fetchData();
  }

  send ()
  {
    let self = this;
    self.appService.setLoaderStatus(true);
    self.loaderOverlay = true;
    self.api.save('quotes/sendMail/'+self.project['id']+'', this.data).then(function (response) {
      self.data = response;
      self.appService.setLoaderStatus(false);
      self.showSuccess = true;
      self.loaderOverlay = false;
    });
  }

  fetchData ()
  {
    let self = this;
    self.api.read('quotes/quoteMailData/'+self.project['id']+'').then(function (response) {
      self.data = response;
    });
  }

	confirm() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

}
