import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-quick-view-client',
  templateUrl: './quick-view-client.component.html',
  styleUrls: ['./quick-view-client.component.scss']
})
export class QuickViewClientComponent implements OnInit {
  	loaderOverlay = true;
	@Input() public id;
	@Input() public project;
 	item;
	currentUser;
  
	constructor(
      public activeModal: NgbActiveModal,
      public appService: AppService,
      public modalService: NgbModal,
      public authenticationService: AuthenticationService,
      public api: ApiConnectionService,
      public sanitizer: DomSanitizer
  ) {
	let self = this;
	self.authenticationService.getCurrentUser();
	self.authenticationService.data.subscribe(data => {
	  if (Object.keys(data).length) {
		self.currentUser = data;
	  } else {
		self.currentUser = false;
	  }
	});
  }

  ngOnInit(): void {
    let self = this;
    this.appService.setLoaderStatus(true);
    setTimeout(function () {
      self.fetchItem();
    },500);
  }

	confirm () {
		this.activeModal.close(false);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
	
	fetchItem () {	
		let self = this;
		this.api.read('organisations/details/'+self.id+'').then((response) => {		
      self.appService.setLoaderStatus(false);
      self.loaderOverlay = false;
			self.item = response;
		});
	}
  
	openForm (data)
	{
		let self = this;
		let id = self.project['id'];
	  
		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
		  windowClass: 'modal-right',
		  backdrop: 'static',
		  size: 'lg'
		});
		
		filterModal.componentInstance.id = id;
		filterModal.result.then((result) => {
			self.fetchItem();
		})
		.catch((error) => {
		});
	}

	openFormProject () 
	{
		let self = this;
		let id = self.project['id'];
	  
		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
		  windowClass: 'modal-right',
		  backdrop: 'static',
		  size: 'lg'
		});
		
		filterModal.componentInstance.id = id;
		filterModal.componentInstance.currentTab = 'status';
		
		filterModal.result.then((result) => {
			self.fetchItem();
		})
		.catch((error) => {
		});
	}
}
