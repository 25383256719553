
<div class="row m-0" style="min-height:100vh;">
    <div class="col-6 bg-light d-flex align-items-center">
        <section class="w-100" *ngIf="loading">
            <div class="text-center py-5" >
                <img style="width:140px;" src="/assets/images/loader.gif">
            </div>
        </section>
        <section *ngIf="loading == false" class="w-100">
            
            <div class="py-2"><a routerLink="login">Terug</a></div>
        </section>
    </div>
    <div class="col-6 bg-login">
    </div>
</div>