<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div *ngIf="phase">
        <div class="modal-header">
            <h4 class="modal-title">Fase</h4>
            <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
        </div>
        <div class="modal-body p-5"> 
            <div class="row">
                <div class="col-2 pt-2">
                    Nummer 
                </div>
                <div class="col-9">
                    <input class="form-control" type="text" placeholder="" [(ngModel)]="phase['number']" />
                </div>
            </div>
            <div style="clear:both;"></div>
            <div class="row mt-3">
                <div class="col-2 pt-2">
                    Datum 
                </div>
                <div class="col-9">
                    <input class="form-control" type="date" placeholder="" [(ngModel)]="phase['startAt']" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="confirm()" class="btn btn-success with-icon">
                Opslaan 
                <i class="bi bi-arrow-right"></i>
            </button>
        </div>  
    </div>
</div>