import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	Renderer2,
  } from "@angular/core";
  import { DomSanitizer } from "@angular/platform-browser";
  import { ActivatedRoute } from "@angular/router";
  import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
  import { ModalConfirmDeleteComponent } from "src/app/modals/modal-confirm-delete/modal-confirm-delete.component";
  import { FilePreviewComponent } from "src/app/modals/file-preview/file-preview.component";
  import { AppService } from "src/app/services/app.service";
  import { ApiConnectionService } from "src/app/services/core/api-connection.service";
  import { ConfigService } from "src/app/services/core/config.service";
  import { ProjectPermitStatusComponent } from "../project-permit-status/project-permit-status.component";
  import { EditFileNameComponent } from "./edit-file-name/edit-file-name.component";
  import { AuthenticationService } from "src/app/services/core/authentication.service";
  
  @Component({
	selector: "app-project-files",
	templateUrl: "./project-files.component.html",
	styleUrls: ["./project-files.component.scss"],
  })
  export class ProjectFilesComponent implements OnInit {
	@Input() public project;
	@Input() public hideMeta = false;
	@Input() public reload;
	@Input() public target = "GENERAL";
	@Input() public type = "NORMAL";
	@Input() public fileType = "projectFiles";
	@Output() reloadAction = new EventEmitter();
  
	loading = false;
	loaderOverlay = false;
	showDropDown = false;
  
	files: File[] = [];
	projectFiles;
	selectedFiles = {};
  
	dropzoneHovered = false;
  
	fileCategoryOptions;
	targetCategory = "";
	showCategoryChange = false;
  
	currentUser;
  
	constructor(
	  private appService: AppService,
	  private route: ActivatedRoute,
	  private authenticationService: AuthenticationService,
	  private sanitizer: DomSanitizer,
	  private config: ConfigService,
	  private api: ApiConnectionService,
	  private render: Renderer2,
	  private modalService: NgbModal
	) {
	  this.fileCategoryOptions = this.config.get("project_category_options");
  
	  let self = this;
	  this.render.listen("window", "click", (evt) => {
		if (!evt.target.classList.contains("dropdown-toggle")) {
		  self.showDropDown = false;
		}
	  });
	  self.authenticationService.getCurrentUser();
	  self.authenticationService.data.subscribe((data) => {
		if (Object.keys(data).length) {
		  self.currentUser = data;
		} else {
		  self.currentUser = false;
		}
	  });
	}
  
	ngOnInit(): void {
	  this.getFiles();
	}
  
	ngOnChanges(changes: any) {
	  console.log(this.reload);
	  this.getFiles();
	}
  
	transform(value) {
	  return this.sanitizer.bypassSecurityTrustHtml(value);
	}
  
	toggleDropdown() {
	  if (this.showDropDown === false) {
		this.showDropDown = true;
	  } else {
		this.showDropDown = false;
	  }
	}
  
	handleCategoryChange(event) {
	  this.targetCategory = event.target.value;
	}
  
	setShowCategory(value) {
	  this.showCategoryChange = value;
	  this.showDropDown = false;
	}
  
	saveCategoryChange() {
	  this.loaderOverlay = true;
	  this.showDropDown = false;
  
	  this.showCategoryChange = false;
	  this.showDropDown = false;
  
	  let self = this;
	  for (var prop in this.selectedFiles) {
		if (Object.prototype.hasOwnProperty.call(this.selectedFiles, prop)) {
		  self.appService.setLoaderStatus(true);
		  self.appService.setSystemMessageType("SUCCESS");
		  self.appService.setSystemMessageText("Categorie gewijzigd");
  
		  self.api
			.delete(
			  ""+self.fileType+"/changeCategory/" +
				prop +
				"/" +
				self.targetCategory +
				""
			)
			.then((response) => {
			  self.appService.setLoaderStatus(false);
			  self.appService.setSystemMessageLoading(true);
  
			  delete self.selectedFiles[prop];
			  self.saveCategoryChange();
			})
			.catch(function (error) {
			  self.appService.setLoaderStatus(false);
			  delete self.selectedFiles[prop];
			  self.getFiles();
			  self.loaderOverlay = false;
			});
		  return true;
		}
	  }
	  this.loaderOverlay = false;
	  self.getFiles();
	}
  
	getFiles() {
	  let self = this;
	  let id = self.project["id"];
	  if (!self.project) {
		return;
	  }
	  self.projectFiles = [];
	  self.dropzoneHovered = false;
	  let target = self.target === "GENERAL" ? "" : self.target;
	  let type = (self.fileType === 'projectPhotos' ? "photos" : "files");
	  this.api
		.read("projects/details/" + id + "/" + type + "/" + target + "")
		.then((response) => {
		  self.projectFiles = response["items"];
		});
	}
  
	onSelect(event) {
	  let self = this;
	  this.loading = true;
	  this.files.push(...event.addedFiles);
  
	  const formData = new FormData();
  
	  for (var i = 0; i < this.files.length; i++) {
		formData.append("file[]", this.files[i]);
	  }
  
	  self.loaderOverlay = true;
	  self.appService.setLoaderStatus(true);
	  this.api
		.saveFile(
		  ""+self.fileType+"/upload/" + this.project["id"] + "/" + self.target + "",
		  formData
		)
		.then(function (response) {
		  self.files = [];
		  self.loading = false;
		  self.getFiles();
		  self.loaderOverlay = false;
		  self.appService.setLoaderStatus(false);
		  self.appService.setSystemMessageLoading(true);
		  self.appService.setSystemMessageText("Bestanden zijn opgeslagen");
		  self.appService.setSystemMessageType("SUCCESS");
		  self.dropzoneHovered = false;
		  self.reloadAction.emit(self.project);
		});
	}
  
	onRemove(event) {
	  this.files.splice(this.files.indexOf(event), 1);
	}
  
	delete(object) {
	  let self = this;
	  self.appService.setLoaderStatus(true);
	  this.api
		.delete(""+self.fileType+"/delete/" + object.id + "")
		.then((response) => {
		  self.getFiles();
		  self.appService.setLoaderStatus(false);
		  self.appService.setSystemMessageLoading(true);
		  self.appService.setSystemMessageType("DANGER");
		  self.appService.setSystemMessageText("Bestand verwijderd");
		  self.reloadAction.emit(self.project);
		})
		.catch(function (error) {
		  self.appService.setLoaderStatus(false);
		});
	}
  
	deleteList() {
	  this.loaderOverlay = true;
	  this.showDropDown = false;
	  let self = this;
	  for (var prop in this.selectedFiles) {
		if (Object.prototype.hasOwnProperty.call(this.selectedFiles, prop)) {
		  self.appService.setLoaderStatus(true);
		  self.api
			.delete(""+self.fileType+"/delete/" + prop + "")
			.then((response) => {
			  self.appService.setLoaderStatus(false);
			  self.appService.setSystemMessageLoading(true);
			  self.appService.setSystemMessageType("DANGER");
			  self.appService.setSystemMessageText("Bestand verwijderd");
			  self.reloadAction.emit(self.project);
  
			  delete self.selectedFiles[prop];
			  self.deleteList();
			})
			.catch(function (error) {
			  self.appService.setLoaderStatus(false);
			  delete self.selectedFiles[prop];
			  self.getFiles();
			  self.loaderOverlay = false;
			});
		  return true;
		}
	  }
	  this.loaderOverlay = false;
	  self.getFiles();
	}
  
	confirmDelete2(object) {
	  let self = this;
  
	  const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
		windowClass: "second-modal",
		backdropClass: "second-modal-backdrop",
	  });
	  deleteModal.componentInstance.message =
		"Weet je zeker dat je bestanden wilt verwijderen?";
	  deleteModal.result.then((result) => {
		if (result) {
		  self.delete(object);
		}
	  });
	}
  
	confirmDelete() {
	  let self = this;
  
	  const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
		windowClass: "second-modal",
		backdropClass: "second-modal-backdrop",
	  });
	  deleteModal.componentInstance.message =
		"Weet je zeker dat je bestanden wilt verwijderen?";
	  deleteModal.result.then((result) => {
		if (result) {
		  self.deleteList();
		}
	  });
	}
  
	onFilesAdded(event) {
	  this.files.push(...event.addedFiles);
	  this.dropzoneHovered = false;
	  this.readFile(this.files[0]).then((fileContents) => {});
	}
  
	private async readFile(file: File): Promise<string | ArrayBuffer> {
	  return new Promise<string | ArrayBuffer>((resolve, reject) => {
		const reader = new FileReader();
  
		reader.onload = (e) => {
		  return resolve((e.target as FileReader).result);
		};
  
		reader.onerror = (e) => {
		  console.error(`FileReader failed on file ${file.name}.`);
		  return reject(null);
		};
  
		if (!file) {
		  console.error("No file to read.");
		  return reject(null);
		}
  
		reader.readAsDataURL(file);
	  });
	}
  
	toggleSelect(fileId) {
	  if (this.selectedFiles && this.selectedFiles[fileId]) {
		delete this.selectedFiles[fileId];
	  } else {
		this.selectedFiles[fileId] = fileId;
	  }
	}
  
	showPreview(file, fileList) {
	  let link =
		'<object data="' +
		file["viewUrl"] +
		'" style="width:100%;height:600px;" type="application/pdf"></object>';
	  if (file["isImage"]) {
		link =
		  '<img src="' + file["viewUrl"] + '" style="width:100%;height:100%;">';
	  }
	  const modal = this.modalService.open(FilePreviewComponent, {
		size: "lg",
		backdropClass: 'second-modal-backdrop',
		windowClass: 'second-modal'
	  });
  
	  modal.componentInstance.message = link;
	  modal.componentInstance.file = file;
	  modal.componentInstance.fileList = fileList;
	  modal.result.then((result) => {}).catch((error) => {});
	}
  
	sanitize(url: string) {
	  return this.sanitizer.bypassSecurityTrustUrl(url);
	}
  
	editPageProjectPermitStatus(project, type) {
	  let self = this;
  
	  const filterModal = this.modalService.open(ProjectPermitStatusComponent, {
		size: "lg",
		backdrop: "static",
	  });
  
	  filterModal.componentInstance.type = type;
	  filterModal.componentInstance.project = project;
	  filterModal.result
		.then((result) => {
		  this.reloadAction.emit(project);
		  self.getFiles();
		})
		.catch((error) => {
		  this.reloadAction.emit(project);
		});
	}
  
	editFilename(project, projectFile) {
	  let self = this;
  
	  const filterModal = this.modalService.open(EditFileNameComponent, {
		size: "lg",
		windowClass: "second-modal",
		backdropClass: "second-modal-backdrop",
		backdrop: "static",
	  });
  
	  filterModal.componentInstance.project = project;
	  filterModal.componentInstance.target = ""+self.fileType+"";
	  filterModal.componentInstance.projectFile = projectFile;
	  filterModal.result.then((result) => {}).catch((error) => {});
	}
  }
  