import { Component, OnInit, Input, Output, Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-inventory-form',
  templateUrl: './inventory-form.component.html',
  styleUrls: ['./inventory-form.component.scss']
})
export class InventoryFormComponent implements OnInit {
	@Input() public id;
	@Input() public title;
	@Input() public button;
	@Input() public closeAfterSave = false;	
	@Input() public message;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
	loaderOverlay = false;

	item;
  currentUser;
  errors = {};
  errormessage;
  imageBase64 = '';
	
	constructor(
		public service: UsersService,
		public modalService: NgbModal,
		public appService: AppService,
		private authenticationService: AuthenticationService,
		public apiService: ApiConnectionService,
		public renderer: Renderer2,
		public config: ConfigService,
		public activeModal: NgbActiveModal
	) {
		let self = this;
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
		  if (Object.keys(data).length) {
			self.currentUser = data;
		  } else {
			self.currentUser = false;
		  }
		});
	}

	ngOnInit(): void {
    this.fetchItem();
	}

	ngOnChanges(changes: any) {
		this.fetchItem();
	}
	
	fetchItem () {	
		let self = this;
		this.service.read('inventory/details/'+this.id+'').then((response) => {		
			self.item = response;
		});
	}

	save() {
		let self = this;
		self.errors = [];
		
		self.loaderOverlay = true;
		
		self.appService.scrollToElementTop('user-form');

		self.appService.setLoaderStatus(true);
	
    let postData = {};
    postData = self.item;
    postData['imageData'] = self.imageBase64;

		this.service.save('inventory/save', postData).then((response) => {
			self.item = response;
			self.loaderOverlay = false;

			if (self.closeAfterSave === true)
			{
				self.activeModal.close({
					id: self.item['id'],
					name: self.item['name']
				});
			}

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_SAVED');
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

		}).catch (function (response) {

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;
			
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

  setToBase64(event) {
    let self = this;

    var fileInput = document.getElementById('fileInput');
    var file = event.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function() {
      var img = new Image();
      img.src = reader.result.toString();

      img.onload = function() {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          var MAX_WIDTH = 500;
          var width = img.width;
          var height = img.height;

          if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          var resizedBase64 = canvas.toDataURL('image/jpeg');
          self.imageBase64 = resizedBase64;
      };
  };

  reader.readAsDataURL(file);
}

resetImageBase64 () {
  this.imageBase64 = '';
}

	dismiss() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}
}