import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LogService }  from '../../services/core/log.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config = {};

  constructor(
	public http: HttpClient,
	public logService: LogService
) { 
	this.config['api_url'] = environment['apiUrl'];
	this.config['front_url'] = environment['frontUrl'];
	this.config['login_url'] = environment['frontUrl'] + "login";
	this.config['profile_url'] = environment['frontUrl'] + "profielpagina";
	this.config['portal_url'] = environment['portalUrl'];
	this.config['cdn_url'] = environment['cdnUrl'];
	this.config['system_key'] = environment['systemKey'];
	this.config['sales_website'] = environment['salesWebsite'];
	this.config['contact_email'] = environment['contactEmail'];
	this.config['contact_phone'] = environment['contactPhone'];
	this.config['title'] = environment['title'];
	this.config['slogan'] = environment['slogan'];
	this.config['description'] = environment['description'];	
  }
  
  load () {
	var self = this;
	let apiUrl =  this.get('api_url');
	return new Promise(function(resolve, reject) 
	{
		self.http.get(apiUrl + 'config', { withCredentials: true })
		.subscribe(
			data => 
			{
				let response: any = data;	
				for (let key in response.configuration) {
					self.config[key] = response.configuration[key];
				}
				resolve(true);
			},
			error => {
				self.logService.sendReport(apiUrl + 'config',error['status'],error['message']);
			}
		);
	});
  }
  
  get (key) {
  	return this.config[key];
  }
}
