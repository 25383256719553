import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-quote-mails',
  templateUrl: './quote-mails.component.html',
  styleUrls: ['./quote-mails.component.scss']
})
export class QuoteMailsComponent implements OnInit {
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Input() public project;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
  showSuccess = false;
	content;
	loaderOverlay = false;
  files = [];
  photos = [];
  previewUrl = '';
  photoTabExtended = false;
  data;
  mails;
  currentMail;

	constructor(
  		public activeModal: NgbActiveModal,
		  public appService: AppService,
      public config: ConfigService,
      public api: ApiConnectionService,
      public sanitizer: DomSanitizer
  	) { }

  ngOnInit(): void {
    this.fetchMails();
  }

  setCurrentMail (mail)
  {
    this.currentMail = mail;
  }

  fetchMails ()
  {
    let self = this;
    this.api.read('projects/mails/'+self['project']['id']+'').then(function (response) {
      self.mails = response;
      self.setCurrentMail(self.mails[0]);
    });
  }

	confirm() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}


}
