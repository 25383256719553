import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { OrganisationsService }  from '../../services/organisations.service';
import { UsersService }  from '../../services/users.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService }  from '../../services/core/config.service';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { UserEditModalComponent } from 'src/app/components/users/user-edit-modal/user-edit-modal.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-organisations-form',
  templateUrl: './organisations-form.component.html',
  styleUrls: ['./organisations-form.component.scss']
})
export class OrganisationsFormComponent implements OnInit {
	@Input() public id;
	error : boolean = false;
	item : any = {};
	users : any = [];
	errors : any = [];
	mutateForm;
	errormessage = false;
	successmessage = false;
	tabs : any = {'info': true};
	loaderOverlay = false;
	keyword = '';
	
	constructor(
  		public organisationsService: OrganisationsService, 
  		public usersService: UsersService,
  		public collectionManager: CollectionManagerService,
 		private appService: AppService,
		private app: AppService,
		private modalService: NgbModal,
		private config: ConfigService,
  		private ngxLoader: NgxUiLoaderService,
		public activeModal: NgbActiveModal
	) { }
	
	ngOnInit(): void {
		try{
			let key = sessionStorage.getItem('organisation-tab');
			if (key) {
				this.changeTab(key);
			}
		} catch (e) {
		}

		this.fetchItem(); 
	}
	
	changeTab (target) {
		for (let key in this.tabs) {
			this.tabs[key] = false;
		}
		this.tabs[target] = true;
		sessionStorage.setItem('organisation-tab', target);
	}
	
	fetchItem () {
		let id = this.id;

		if (!id)
		{
			id = '';
		}

		let self = this;
		this.organisationsService.read('organisations/details/'+id+'').then((response) => {
	      self.item = response;
		});
		this.fetchUsers();
	}
	
	fetchUsers () {
		let id = this.id;
		if (!id)
		{
			id = '';
		}

		let self = this;
	    this.ngxLoader.start();
		this.organisationsService.read('organisations/contacts/'+id+'').then((response) => {
	      self.users = response['items'];
	      self.ngxLoader.stop();
		});
	}
	
	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;
		self.loaderOverlay = true;
		
		let newItem = (!this.id ? true : false);

		self.app.setLoaderStatus(true);
		this.organisationsService.save('organisations/save', self.item).then((response) => {
			
			self.item = response;
			self.scrollToAlert();

			self.activeModal.close({
				id: self.item['id'],
				clientContacts: self.item['clientContacts'],
				name: self.item['name'],
			});

			self.app.setSystemMessageLoading(true);
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageType('SUCCESS');
			self.loaderOverlay = false;

			if (newItem === true) {
				self.app.setSystemMessageText('DEFAULT_ADDED');
			} else {
				self.app.setSystemMessageText('DEFAULT_SAVED');
			}

		}).catch (function (response) {
			self.app.setLoaderStatus(false);			
			self.app.setSystemMessageType('DANGER');
			self.app.setSystemMessageText('DEFAULT_ERROR');
			self.app.setSystemMessageLoading(true);
			self.loaderOverlay = false;

			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
			self.scrollToAlert();
		});
	}
	
	scrollToAlert(): void {
		const element = document.querySelector(".alert-message-container");
		if (element) {
			element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		}
	}

	delete (user) {
		let self = this;
		self.appService.setLoaderStatus(true);
		this.usersService.delete('users/delete/'+user.id+'').then((response) => {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('DANGER');
			self.appService.setSystemMessageText('DEFAULT_DELETED');
			self.appService.setLoaderStatus(false);
		}).catch (function (error) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('DANGER');
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setLoaderStatus(false);	
		});	
	}
	
	confirmDelete(user) {
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je '+user.name+' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.delete(user);
				self.fetchUsers();
			}
		});
	}

	openForm (data)
	{
		let self = this;
	  
		const filterModal = this.modalService.open(UserEditModalComponent, {
		  windowClass: 'modal-right',
		  backdrop: 'static',
		  size: 'lg'
		});
		
		filterModal.componentInstance.id = data['id'];
		filterModal.componentInstance.organisationId = data['organisationId'];
		filterModal.componentInstance.roleKey = data['role'];
		filterModal.componentInstance.closeAfterSave = true;
		filterModal.result.then((result) => {
		  self.fetchItem();
		})
		.catch((error) => {
		});
	}

	dismiss() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}

	searchUsers ()
	{
		let list = [];
		for (let index = 0; index < this.users.length; ++index) {
		  let contact = this.users[index];
		  let string = ''+contact['name'].toLowerCase()+'';
		  let result = string.includes(this.keyword.toLowerCase()); 
		  
		  if (this.keyword == '' || result)
		  {
			list.push(contact);
		  }
		}
		return list;
	}
}
