<div class="modal-header">
	<h5 class="modal-title">Filter toevoegen</h5>
    <button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
</div>
<div class="modal-body">
	<div clas="row">
		<div class="col-12" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px;">
			<small>Selecteer een filter uit de onderstaande opties</small>
		</div>
	</div>
	<div clas="row" *ngFor="let filterOption of filterOptions">
		<div class="col-12 filter-option" (click)="editFilter(filterOption['key'])" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
			{{filterOption['label']}}
		</div>
	</div>
</div>
