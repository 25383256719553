
<div class="row m-0" style="min-height:100vh;">
    <div class="col-6 bg-light d-flex align-items-center">
        <section class="w-100" *ngIf="loading">
            <div class="text-center py-5" >
                <img style="width:140px;" src="/assets/images/loader.gif">
            </div>
        </section>
        <section *ngIf="loading == false" class="w-100">
            <div class="login-container">
                <figure class="login-logo">
                    <img src="/assets/images/logo.webp" alt="Logo">
                </figure>
                <h1>Wachtwoord vergeten</h1>
                <small class="d-block mb-4 text-muted">Vul je e-mail in om verder te gaan.</small>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" >
                    <div class="form-group mt-3">
                        <input type="text" placeholder="Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">E-mail is vereist</div>
                            <div *ngIf="f.email.errors.email">Het opgegeven e-mailadres is ongeldig</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="submit" class="btn btn-success mt-3 with-icon">Verstuur <i class="bi bi-arrow-right-circle-fill"></i></button>
                        </div>
                        <div class="col-6 text-end">
                            <a class="pt-4 d-inline-block" routerLink="/login" href="#">Terug naar login</a>
                        </div>
                    </div>
                    
                </form>
            </div>
        </section>
    </div>
    <div class="col-6 bg-login">
    </div>
</div>