import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent implements OnInit {

  constructor(
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.appService.setPageTitle('Kwaliteit');
    this.appService.setNavKey('quality');
  }

}
