import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { FilePreviewComponent } from 'src/app/modals/file-preview/file-preview.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ProjectPermitStatusComponent } from './project-permit-status/project-permit-status.component';
import { QuickViewClientComponent } from 'src/app/quick-views/quick-view-client/quick-view-client.component';
import { QuickViewContactComponent } from 'src/app/quick-views/quick-view-contact/quick-view-contact.component';
import { QuickViewUserGroupComponent } from 'src/app/quick-views/quick-view-user-group/quick-view-user-group.component';
import { QuickViewUserSiteManagerComponent } from 'src/app/quick-views/quick-view-user-site-manager/quick-view-user-site-manager.component';
import { EditFileNameComponent } from './project-files/edit-file-name/edit-file-name.component';
import { QuoteAcceptedFormComponent } from 'src/app/components/projects/quote-accepted-form/quote-accepted-form.component';
import { SendQuoteComponent } from './send-quote/send-quote.component';
import { QuoteMailsComponent } from './quote-mails/quote-mails.component';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ProjectFilesComponent } from './project-files/project-files.component';
import { ProjectInfoFilesComponent } from 'src/app/components/projects/project-info-files/project-info-files.component';

@Component({
	selector: 'app-projects-detail',
	templateUrl: './projects-detail.component.html',
	styleUrls: ['./projects-detail.component.scss']
})
export class ProjectsDetailComponent implements OnInit {

	statusOptions = [];
	quoteAcceptedOptions = [];
	loading = true;
	loaderOverlay = false;

	tabs: any = { 'quote': true };
	files: File[] = [];
	photoFiles: File[] = [];
	editPath = 'projects/detail/';

	quoteChange = false;

	refresh = 1;
	item;

	projectFiles;
	projectPhotos;

	showDropDown = false;

	downloadQuotePdf = '';
	downloadAbcPdf = '';
	downloadCalculationPdf = '';

	currentUser;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private authenticationService: AuthenticationService,
		private renderer: Renderer2,
		private config: ConfigService,
		private api: ApiConnectionService,
		private modalService: NgbModal
	) { 
		let self = this;

		this.item = {};
		this.statusOptions = this.config.get('project_status_options');
		this.quoteAcceptedOptions = this.config.get('quote_accepted_options');

		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
		  if (Object.keys(data).length) {
			self.currentUser = data;
		  } else {
			self.currentUser = false;
		  }
		});

		this.renderer.listen('window', 'click', (evt) => {
			if (self.quoteChange == false && this.showDropDown == false)
			{
				return;
			}
			if (!evt.target.classList.contains('quote-change') && (!evt.target.parentElement.classList.contains('quote-change'))) {
				self.quoteChange = false;
			}
			if (!evt.target.classList.contains('dropdown-toggle') && (!evt.target.parentElement.classList.contains('dropdown-toggle'))) {
				self.showDropDown = false;	
			}
		});
	}

	openFileForm (data)
	{
	  let self = this;
	  
	  const filterModal = this.modalService.open(ProjectInfoFilesComponent , {
		windowClass: 'modal-right modal-right-xl',
		size: 'lg'
	  });
	  
	  filterModal.componentInstance.id = data['id'];
	  filterModal.result.then((result) => {
		  self.refresh = Math.random();
	  })
	  .catch((error) => {
  
	  });
	}

	changeStatus (value)
	{
	  let self = this;
	  self.quoteChange = false;
	  self.appService.setSystemMessageText('Status gewijzigd');
	  self.appService.setSystemMessageType('SUCCESS');
	  self.appService.setLoaderStatus(true);
	  this.api.read('projects/changeQuoteStatus/'+self.item['id']+'/'+value+'').then(function (response) {
		self.fetchItem();
		self.appService.setLoaderStatus(false);
		self.appService.setSystemMessageLoading(true);
	  });
	}
	
	fetchItem () {
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');

		this.downloadQuotePdf = this.config.get('api_url') + 'projects/pdf/'+id+'';
		this.downloadCalculationPdf = this.config.get('api_url') + 'projectCalculationItems/pdf/'+id+'';
		this.downloadAbcPdf = this.config.get('api_url') + 'projectAbc/pdf/'+id+'';

		this.api.read('projects/details/'+id+'').then((response) => {
			if (response['isQuote']) {
				this.appService.setPageTitle('Offertes');
				this.appService.setNavKey('quotes');
			} else if (response['isProject']) {
				this.appService.setPageTitle('Projecten');
				this.appService.setNavKey('projects');
			} else {
				this.appService.setPageTitle('Archief');
				this.appService.setNavKey('archive');				
			}
			
			setTimeout(function () {
				self.loading = false;
			},1000);

			self.item = response;
		});
	}
  
	transform(value) {
	  return this.sanitizer.bypassSecurityTrustHtml(value);
   	}
	
   getPhotos () {	
	   let self = this;
	   let id = this.route.snapshot.paramMap.get('id');
	   self.projectPhotos = [];
	   this.api.read('projects/details/'+id+'/photos').then((response) => {		
		   self.projectPhotos = response['items'];
	   });
   }

	ngOnInit(): void {
		try {
			let key = sessionStorage.getItem('project-detail-tab');
			if (key) {
				this.changeTab(key);
			}
		} catch (e) {
		}
		this.fetchItem();
		this.getPhotos();

	}

	changeTab(target) {
		for (let key in this.tabs) {
			this.tabs[key] = false;
		}
		this.tabs[target] = true;
		sessionStorage.setItem('project-detail-tab', target);
	}
  
	openForm (data)
	{
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');
	  
		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
		  windowClass: 'modal-right',
		  backdrop: 'static',
		  size: 'lg'
		});
		
		filterModal.componentInstance.id = id;
		filterModal.result.then((result) => {
			self.fetchItem();
		})
		.catch((error) => {
		});
	}

	openFormProject () 
	{
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');
	  
		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
		  windowClass: 'modal-right',
		  backdrop: 'static',
		  size: 'lg'
		});
		
		filterModal.componentInstance.id = id;
		filterModal.componentInstance.currentTab = 'status';
		
		filterModal.result.then((result) => {
			self.fetchItem();
		})
		.catch((error) => {
		});
	}

	showPreview (file, fileList)
	{
		let link = '<object data="'+file['viewUrl']+'" style="width:100%;height:600px;" type="application/pdf"></object>';
		if (file['isImage'])
		{
			link = '<img src="'+file['viewUrl']+'" style="width:100%;height:100%;">';
		}
		const modal = this.modalService.open(FilePreviewComponent, {
			size: 'lg'
		  });
		  
		  modal.componentInstance.message = link;
		  modal.componentInstance.file = file;
		  modal.componentInstance.fileList = fileList;
		  modal.result.then((result) => {
			  
		  })
		  .catch((error) => {
		  });
	}

	/*
	*	START PHOTOS
	*/
	onPhotoSelect(event) {
		let self = this;
		// this.loading = true;
		this.photoFiles.push(...event.addedFiles);
	
		const formData = new FormData();
	
		for (var i = 0; i < this.photoFiles.length; i++) {
			formData.append("file[]", this.photoFiles[i]);
		}
	
		self.loaderOverlay = true;
		self.appService.setLoaderStatus(true);
		this.api.saveFile('projectPhotos/upload/' + this.item['id'] + '', formData).then(function(response) {
			self.photoFiles = [];
			// self.loading = false;
			self.loaderOverlay = false;
			self.appService.setLoaderStatus(false);
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('Foto\'s zijn opgeslagen');
			self.appService.setSystemMessageType('SUCCESS');
			self.getPhotos();
		});
	}
	
	onPhotoRemove(event) {
		this.photoFiles.splice(this.photoFiles.indexOf(event), 1);
	}
	
	
	deletePhoto(object) {
		let self = this;
		self.appService.setLoaderStatus(true);
		this.api.delete('projectPhotos/delete/' + object.id + '').then((response) => {
			self.getPhotos();
			self.appService.setLoaderStatus(false);
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('DANGER');
			self.appService.setSystemMessageText('Foto verwijderd');
		}).catch(function(error) {
			self.appService.setLoaderStatus(false);
		});
	}
	
	confirmDeletePhoto(object) {
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je ' + object.name + ' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.deletePhoto(object);
			}
		});
	}
	
	onPhotoFilesAdded(event) {
		this.photoFiles.push(...event.addedFiles);
	
		this.readPhotoFile(this.photoFiles[0]).then(fileContents => {
	
		});
	}
	
	private async readPhotoFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();
	
			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};
	
			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};
	
			if (!file) {
				console.error('No file to read.');
				return reject(null);
			}
	
			reader.readAsDataURL(file);
		});
	}

	/*
	*	END PHOTOS
	*/
	editPageProjectPermitStatus (project, type)
	{
		let self = this;
	  
		const filterModal = this.modalService.open(ProjectPermitStatusComponent, {
		  size: 'lg',
		  backdrop: 'static'
		});
		
		filterModal.componentInstance.type = type;
		filterModal.componentInstance.project = project;
		filterModal.result.then((result) => {
			self.fetchItem();
			self.refresh = Math.random();
		})
		.catch((error) => {
			self.fetchItem();
			self.refresh = Math.random();
		});
	}

	quickViewClient () {
		let self = this;
	  
		const modal = this.modalService.open(QuickViewClientComponent, {
			windowClass: 'modal-right',
			size: 'lg'
		});

		modal.componentInstance.id = self.item['organisationId'];
		modal.componentInstance.project = self.item;
	}

	quickViewContact ()  {
		let self = this;
	  
		const modal = this.modalService.open(QuickViewContactComponent, {
			windowClass: 'modal-right',
			size: 'lg'
		});

		modal.componentInstance.project = self.item;
		modal.componentInstance.id = self.item['contactPersonId'];
	}

	reload(item) {
		let id = this.route.snapshot.paramMap.get('id');
		let self = this;
		this.api.read('projects/details/'+id+'').then((response) => {
			self.item['klicStatus'] = response['klicStatus'];
			self.item['verkeersplanStatus'] = response['verkeersplanStatus'];
			self.item['tvmStatus'] = response['tvmStatus'];
			self.item['lisStatus'] = response['lisStatus'];
			self.item['klicStatus'] = response['klicStatus'];
			self.item['verkeersplanStatusLabel'] = response['verkeersplanStatusLabel'];
			self.item['tvmStatusLabel'] = response['tvmStatusLabel'];
			self.item['lisStatusLabel'] = response['lisStatusLabel'];
		});
	}


	editFilename (project, projectFile)
	{
		let self = this;
	  
		const filterModal = this.modalService.open(EditFileNameComponent, {
		  size: 'lg',
		  windowClass: 'second-modal',
		  backdropClass: 'second-modal-backdrop',
		  backdrop: 'static'
		});
		
		filterModal.componentInstance.project = project;
		filterModal.componentInstance.target = 'projectPhotos';
		filterModal.componentInstance.projectFile = projectFile;
		filterModal.result.then((result) => {
			
		})
		.catch((error) => {
			
		});
	}
	
	quickViewUserGroup ()  {
		let self = this;
	  
		const modal = this.modalService.open(QuickViewUserGroupComponent, {
			windowClass: 'modal-right',
			size: 'lg'
		});

		modal.componentInstance.id = self.item['userGroupId'];
		modal.componentInstance.project = self.item;
	}
	
	quickViewSiteManager ()  {
		let self = this;
	  
		const modal = this.modalService.open(QuickViewUserSiteManagerComponent, {
			windowClass: 'modal-right',
			size: 'lg'
		});
		modal.componentInstance.id = self.item['siteManagerId'];
		modal.componentInstance.project = self.item;
	}
	
	quickViewAuthor ()  {
		let self = this;
	  
		const modal = this.modalService.open(QuickViewContactComponent, {
			windowClass: 'modal-right',
			size: 'lg'
		});
		modal.componentInstance.id = self.item['authorId'];
		modal.componentInstance.project = self.item;
	}
	
	toggleQuoteChange ()
	{
		this.quoteChange = true;
		return;
		
		if (this.quoteChange === false) {
			this.quoteChange = true;
		} else {
			this.quoteChange = false;
		}
	}

	toggleDropdown () 
	{
		 if (this.showDropDown === false) {
			 this.showDropDown = true;
		 } else {
			 this.showDropDown = false;
		 }
	}

	openAcceptedStatus (event, item)
	{
	  event.stopPropagation();
	  let self = this;
	  
	  const filterModal = this.modalService.open(QuoteAcceptedFormComponent, {
	  });
	  
	  filterModal.componentInstance.item = item;
	  filterModal.result.then((result) => {
		  self.fetchItem();
	  })
	  .catch((error) => {
  
	  });
	  
	}

	sendQuote ()
	{
		let self = this;
		self.showDropDown = false;

		const modal = this.modalService.open(SendQuoteComponent, {
			backdrop: 'static',
			size: 'lg'
		});
		modal.componentInstance.project = self.item;
		modal.result.then((result) => {
			if (result === 'SHOW_FILES')
			{
				this.changeTab('files');
			}
			self.fetchItem();
		})
		.catch((error) => {
	
		});		
	}

	showMails ()
	{
		let self = this;
		self.showDropDown = false;

		const modal = this.modalService.open(QuoteMailsComponent, {
			size: 'xl'
		});
		modal.componentInstance.project = self.item;
		modal.result.then((result) => {
			if (result === 'SHOW_FILES')
			{
				this.changeTab('files');
			}
			self.fetchItem();
		})
		.catch((error) => {
	
		});		
	}
}
