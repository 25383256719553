import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ProjectFilesComponent } from '../project-files/project-files.component';
import { ProjectMapComponent } from '../project-map/project-map.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-project-prints',
  templateUrl: './project-prints.component.html',
  styleUrls: ['./project-prints.component.scss']
})
export class ProjectPrintsComponent implements OnInit {
  @Input() project;
  items;
  collapse = {};

  constructor(
    private api: ApiConnectionService,
    private modalService: NgbModal,
    private app: AppService
  ) { }

  ngOnInit(): void {
    this.fetchItems();
  }

  fetchItems () 
  {
    let self = this;
    let url = 'prints/all';
    if (this.project && this.project['id'])
    {
      url = 'prints/project/'+this.project['id']+'';
    }
    this.api.read(url).then(function (response) {
      self.items = response['items'];
      if (Object.keys(self.collapse).length < 1)
      {
        for (let i=0;i<self.items.length;i++)
        {
          if (self.items[i]['isPrintFinished'] === false) {
            self.collapse[self.items[i]['id']] = true;
            break;
          }
        }
      }
    });
  }

  updatePhaseSigns (phase, index)
  {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .read("phases/updateSignStatus/" + phase.id + "/"+index+"")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Borden gereed gemeld");
        self.app.setSystemMessageType("SUCCESS");
        setTimeout(function () {
          self.fetchItems();
        }, 10);
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  changeSignStatus (sign, index, status)
  {
    sign['status'+index+''] = status;
    
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .save("phases/updateSign/"+sign['id']+"", sign)
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Succesvol toegevoegd");
        self.app.setSystemMessageType("SUCCESS");
        // self.fetchItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });    
  }

  openVerkeersplan (project)
  {
		let self = this;
		const filterModal = this.modalService.open(ProjectMapComponent, {
		  backdrop: 'static',
		  size: 'lg'
		});
		
		filterModal.componentInstance.item = project;
		filterModal.componentInstance.modal = true;

		filterModal.result.then((result) => {
		})
		.catch((error) => {
		});
  }

  changeCollapse (key, value)
  {
    this.collapse[key] = value;
  }
}
