import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-project-add-sign-modal',
  templateUrl: './project-add-sign-modal.component.html',
  styleUrls: ['./project-add-sign-modal.component.scss']
})
export class ProjectAddSignModalComponent implements OnInit {
	@Input() public project;
	@Input() public phase;

  loaderOverlay;
	signs = [];
	selectedSigns = {};
	keyword = '';
	selectAllSelected = false;

	tabs : any = {'all': true};

	constructor(
    public activeModal: NgbActiveModal,
    public app: AppService,
    public api: ApiConnectionService
  ) { 

  }

	ngOnInit(): void {
	}
  
	ngOnChanges(changes: any) {
	}

	setSignIds (event)
	{
		this.selectedSigns[event['type']] = event['items'];
	}
	
	changeTab (target) {
		for (let key in this.tabs) {
			this.tabs[key] = false;
		}
		this.tabs[target] = true;
		sessionStorage.setItem('add-sign-tab', target);
	}
  
	confirm () {
		let self = this;
		self.loaderOverlay = true;
		self.app.setLoaderStatus(true);			
		this.api.save('phases/addSigns/'+self['phase']['id']+'', {signs: this.selectedSigns}).then(function (response) {
			self.activeModal.close(response);
			self.app.setLoaderStatus(false);		
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageType('SUCCESS');
			self.app.setSystemMessageText('Borden toegevoegd');
			self.loaderOverlay = false;
		}).catch(function (response) {
			self.activeModal.close(response);
			self.app.setLoaderStatus(false);		
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageType('DANGER');
			self.app.setSystemMessageText('Er is een fout opgetreden');
			self.loaderOverlay = false;
		});
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
}
