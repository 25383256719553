<section class="phases-list">
    <div class="phase-list-header">
        <div class="phase-list-collapse">
        </div>
        <div class="phase-list-col-1">
            <div class="phase-sign-name">
                Project
            </div>
            <div class="phase-list-date">
                Datum
            </div>
        </div>
        <div class="phase-sign-status">
            Bord
        </div>
        <div class="phase-sign-action">          
        </div>
    </div>
    <section class="phase-item-container" *ngFor="let phase of items">
        <div class="phase-list-row header">
            <div class="phase-list-collapse pt-4">
                <span class="px-1" style="cursor: pointer;" (click)="changeCollapse(phase['phase']['id'], true)" [ngClass]="{
                    'd-none': collapse[phase['phase']['id']] == true
                }">
                    <i class="bi bi-chevron-down"></i>
                </span>
                <span class="px-1" style="cursor: pointer;" (click)="changeCollapse(phase['phase']['id'], false)" [ngClass]="{
                    'd-none': collapse[phase['phase']['id']] != true
                }">
                    <i class="bi bi-chevron-up"></i>
                </span>
            </div>
            <div class="phase-list-col-1">
                <div class="phase-sign-name">
                    {{phase['project']['title']}}  <span *ngIf="phase['phase']['isPrintFinished'] == true"><i class="bi bi-check2-all"></i></span><br/>
                    <span style="font-weight:normal">Fase {{phase['phase']['number']}}</span>
                </div>
                <div class="phase-list-date">
                    {{phase['phase']['startAtText']}}
                </div>
            </div>
            <div class="phase-sign-status">
                <div class="phase-sign-item-status">
                    
                </div>
            </div>
            <div class="phase-sign-action">
                 <button class="btn btn-secondary" (click)="openVerkeersplan(phase['project'])">Verkeersplan</button>
                <button class="btn btn-secondary" (click)="updatePhaseSigns(phase['phase'], 1)"><i class="bi bi-check2-all"></i></button>
            </div>
        </div>
        <div [ngClass]="{
            'd-none': collapse[phase['phase']['id']] != true
        }">            
            <div class="phase-list-row sign" *ngFor="let sign of phase['printSigns']">
                <div class="phase-list-collapse">
                </div>
                <div class="phase-list-col-1">
                    <div class="phase-list-date">
                        
                    </div>
                    <div class="phase-sign-name">
                    </div>
                </div>
                <div class="phase-sign-status">
                    <img [src]="sign['inventoryImage']" class="sign-thumb" *ngIf="sign['inventoryImage']">
                    {{sign['inventoryName']}} <small class="text-muted" *ngIf="sign['parentId'] || sign['childId']">(Doorgeplaatst)</small>
                </div>
                <div class="phase-sign-action">  
                    <ng-container *ngFor="let index of [1]">
                        <div class="phase-sign-item-status">
                            <button class="btn btn-sign-status btn-ready" (click)="changeSignStatus(sign, index, 'READY')" [ngClass]="{'selected': sign['status'+index+''] == 'READY'}"><span>Gereed</span></button>
                            <button class="btn btn-sign-status btn-not-ready" (click)="changeSignStatus(sign, index, 'NOT_READY')" [ngClass]="{'selected': sign['status'+index+''] == 'NOT_READY'}"><span>Niet gereed</span></button>
                        </div>
                    </ng-container>                    
                </div>
            </div>
        </div>
    </section>                   
</section>