<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h5 class="modal-title" *ngIf="item">
		    <span *ngIf="!item['id']">Bord toevoegen</span>
		    <span *ngIf="item['id']">Bord wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body" id="user-form" *ngIf="item">
		<section class="user-form pt-2" *ngIf="item">
		    <input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="name" class="pb-1">Naam</label>
                </div>
                <div class="col-9">
                    <input type="text" class="form-control" id="name" required [(ngModel)]="item.name" name="name">
                    <div class="error" *ngIf="errors['name']">{{errors['name']}}</div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="name" class="pb-1">Bedrukbaar</label>
                </div>
                <div class="col-9 pt-2">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="printable" role="switch" name="printable" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.printable">
                        <label class="form-check-label" for="printable"></label>
                    </div>
                </div>
            </div>              

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="buyPrice" class="pb-1">Koopprijs</label>
                </div>
                <div class="col-9">
                    <input type="text" class="form-control" id="buyPrice" required [(ngModel)]="item.buyPrice" name="buyPrice">
                    <div class="error" *ngIf="errors['buyPrice']">{{errors['buyPrice']}}</div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="name" class="pb-1">Verhuurpijs</label>
                </div>
                <div class="col-9">
                    <input type="text" class="form-control" id="sellPrice" required [(ngModel)]="item.sellPrice" name="sellPrice">
                    <div class="error" *ngIf="errors['sellPrice']">{{errors['sellPrice']}}</div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="printPrice" class="pb-1">Bedrukprijs</label>
                </div>
                <div class="col-9">
                    <input type="text" class="form-control" id="printPrice" required [(ngModel)]="item.printPrice" name="printPrice">
                    <div class="error" *ngIf="errors['printPrice']">{{errors['printPrice']}}</div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="stock" class="pb-1">Voorraad</label>
                </div>
                <div class="col-9">
                    <input type="text" class="form-control" id="stock" required [(ngModel)]="item.stock" name="stock">
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-3 pt-2">
                    <label for="image" class="pb-1">Afbeelding</label>
                </div>
                <div class="col-9">
                    <div class="inventory-image-container">

                        <img src="" *ngIf="item['imageLink'] && imageBase64 == ''" class="inventory-image">

                        <span class="remove-base64-image" (click)="resetImageBase64()" *ngIf="imageBase64 != ''"><i class="bi bi-x"></i></span>
                        <img [src]="imageBase64" *ngIf="imageBase64 != ''" class="inventory-image">
                        
                        <input type="file" (change)="setToBase64($event)" name="inventoryImage">
                    </div>
                    <textarea [(ngModel)]="imageBase64" style="width:100%;height:120px;display:none;"></textarea>
                </div>
            </div>

		    <div class="mb-5 pb-5"></div>
		</section>
	</div> 
	<div class="modal-footer" *ngIf="item">
		<button (click)="save()" class="btn btn-success">
			<span *ngIf="!item.id"><i class="bi bi-sd-card pe-2"></i> Toevoegen</span>
			<span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
		</button>
	</div>
</section>