<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Week toevoegen</h4>
		<button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-6">
				<label class="d-inline-block pb-1">Jaar</label>
				<select name="year" [(ngModel)]="year" class="form-control">
					<option value="{{year}}" *ngFor="let year of years">
					{{year}}
					</option>
				</select>
			</div>
			<div class="col-6">
				<label class="d-inline-block pb-1">Week</label>
				<select name="week" [(ngModel)]="week" class="form-control">
					<option value="{{week}}" *ngFor="let week of weeks">
						Week {{week}} - ({{ dateString(week) }})
					</option>
				</select>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" type="submit" class="btn btn btn-link me-3" style="text-decoration:none ! important;color:#333333;">Annuleren</button>
		<button (click)="confirm()" type="submit" class="btn btn-success">Toevoegen</button>
	</div>
</section>