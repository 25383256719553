import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PrintDetailsComponent } from '../print-details/print-details.component';

@Component({
  selector: 'app-print-index',
  templateUrl: './print-index.component.html',
  styleUrls: ['./print-index.component.scss']
})
export class PrintComponent implements OnInit {
  link = 'pendingPrints';
  refresh = 0;
  editPath = 'print/';
  mapping = [
    { key: 'name', label: 'Naam', sort: true },
    { key: 'fase', label: 'Fase', sort: true },
    { key: 'trafficPlan', label: 'Verkeersplan', sort: true },
    { key: 'status', label: 'Status', sort: true }
  ];

  constructor(private appService: AppService, private modalService: NgbModal) {
    this.setPageDetails();
  }

  ngOnInit(): void {
  }

  private setPageDetails() {
    this.appService.setPageTitle('Te bedrukken');
    this.appService.setNavKey('print');
  }

  openQuoteStatus(event, item) {
    event.stopPropagation();
    let self = this;

    const filterModal = this.modalService.open(PrintDetailsComponent, {
    });

    filterModal.componentInstance.item = item;
    filterModal.result.then((result) => {
      self.refresh = Math.random();
    })
      .catch((error) => {

      });

  }
}
