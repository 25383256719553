import { Component, OnInit , Input , Output, ViewChild} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {
	@Input() public message;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	constructor(
  		public activeModal: NgbActiveModal
  	) { }

	ngOnInit(): void {
		setTimeout(function () {
			document.getElementById('delete-btn').focus();
		},100);		
	}

	ngAfterViewInit() {
	}

	confirm () {
		this.activeModal.close(true);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
}
