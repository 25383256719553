import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements OnInit {
  projectHours;
  weeks = [];
  years = [];

  startWeek = 14;
  startYear = 2023;
  endWeek = 15;
  endYear = 2023;

  public userDetailId;
  public userDetailPeriod;
  public userDetails;
  public expendedKey = '';

  constructor(  	
    private api: ApiConnectionService,
	  private appService: AppService
    ) {
      let currentDate = new Date;
      currentDate.setDate(currentDate.getDate()-14);

      let startDate = new Date(currentDate.getFullYear(), 0, 1);    
      let tmp1 = 0;
      let tmp2 = 0;
       tmp1 = currentDate.getTime();
       tmp2 = startDate.getTime(); 
    
      var days = Math.floor((tmp1 - tmp2) /
        (24 * 60 * 60 * 1000));
         
      var weekNumber = Math.ceil(days / 7);

      this.startWeek = weekNumber;
      this.startYear = currentDate.getFullYear();

      let currentDate2 = new Date;

      let startDate2 = new Date(currentDate2.getFullYear(), 0, 1);    
      let tmp12 = 0;
      let tmp22 = 0;
       tmp12 = currentDate2.getTime();
       tmp22 = startDate2.getTime(); 
    
      var days2 = Math.floor((tmp12 - tmp22) /
        (24 * 60 * 60 * 1000));
         
      var weekNumber2 = Math.ceil(days2 / 7);

      this.endWeek = weekNumber2;
      this.endYear = currentDate2.getFullYear();

      for (let i=1;i<=53;i++)
      {
        let str = i.toString();
        if (i < 10)
        {
          str = '0' + i;
        }
        this.weeks.push(str);
      }
      var curr = new Date;
      let maxYear = curr.getFullYear();

      maxYear++;
      for (let i=maxYear;i>2021;i--)
      {
        let str = i.toString();
        this.years.push(str);
      }
     }

  ngOnInit(): void {
    this.appService.setPageTitle('Urenregistratie');
    this.appService.setNavKey('hours');
    this.getHours();
  }

  setExpended (key) {
    this.expendedKey = key;
    this.userDetailId = '';
    this.userDetailPeriod = '';
    this.userDetails = '';
  }

  selectAll (event)
  {
    event.target.select();
  }

  setUserDetailId(userId, period)
  {
    this.userDetailId = userId;
    this.userDetailPeriod = period;
    this.getUserDetails();
  }

  getUserDetails ()
  {
		let self = this;
		this.api.read('projectHours/userWeek/'+self.userDetailId+'/'+self.userDetailPeriod+'').then((response) => {		
			self.userDetails = response['items'];
		});
  }

  getHours ()
  {
		let self = this;
		this.api.read('projectHours/list/'+self.startYear+''+self.startWeek+'/'+self.endYear+''+self.endWeek+'').then((response) => {		
			self.projectHours = response['items'];
		});
  }

  clearUserDetailId ()
  {
    this.userDetailId = '';
    this.userDetailPeriod = '';
  }

	dateString (week)
	{
		var curr = new Date; // get current date
		
		let y = this.startYear;
		var d = new Date("Jan 01, " + y + " 01:00:00");
		var dayMs = (24 * 60 * 60 * 1000);
		var offSetTimeStart = dayMs * (d.getDay() - 1);
		var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
		var n1 = new Date(w);
		var n2 = new Date(w + 518400000);
		
		let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
		let month = n1.getMonth();
			month = (month + 1);
		let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

		var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
	  	return firstday;
	}

}
