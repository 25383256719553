<header class="page-header">
    <div class="row">
        <div class="col-8">
            <h1>Notities</h1>
            <h6>Overzicht</h6>
        </div>
        <div class="col-4 text-end pt-3">
            <button class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button>
        </div>
    </div>
</header>
<app-table-guru [refresh]="refresh" [link]="link" [mapping]="mapping" [editPath]="editPath" [showEditButtons]="true"
    (editAction)="editRow($event)" (deleteAction)="deleteRow($event)"></app-table-guru>