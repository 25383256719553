import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { InventoryFormComponent } from './inventory-form/inventory-form.component';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  link = 'inventory';
  refresh = 0;
  editPath = 'inventory';
  mapping = [
    { key: 'imageHtml', label: ' ', sort: false },
    { key: 'name', label: 'Naam', sort: true },
    { key: 'printableIcon', label: 'Drukbaar', sort: true },
    { key: 'buyPriceText', label: 'Inkoop', sort: true },
    { key: 'sellPriceText', label: 'Verkoop', sort: true },
    { key: 'printPriceText', label: 'Plak', sort: true },
    { key: 'stock', label: 'Voorraad', sort: true },
    { key: 'available', label: 'Beschikbaar', sort: true },
    { key: 'unavailable', label: 'In gebruik', sort: true },
  ];

  constructor(
    private appService: AppService,
    private api: ApiConnectionService,
  	private modalService: NgbModal,
  ) {
    this.setPageDetails();
  }

  ngOnInit(): void {
    
  }

  private setPageDetails() {
    this.appService.setPageTitle('Inventaris');
    this.appService.setNavKey('inventory');
  }

  editRow(item) {
	  this.openForm({id: item['id']});
  }

  deleteRow(item) {
	  this.confirmDelete(item);
  }
  
  delete (object) {
    let self = this;
    self.appService.setLoaderStatus(true);
    this.api.delete('inventory/delete/'+object.id+'').then((response) => {
      self.appService.setLoaderStatus(false);
      
      self.appService.setSystemMessageLoading(true);
      self.appService.setSystemMessageText('DEFAULT_DELETED');
      self.appService.setSystemMessageType('DANGER');

      self.refresh = Math.random();
    }).catch (function (error) {
      self.appService.setLoaderStatus(false);		
      
      self.appService.setSystemMessageLoading(true);
      self.appService.setSystemMessageText('DEFAULT_ERROR');
      self.appService.setSystemMessageType('DANGER');
    });	
  }
  
  confirmDelete(item) {
  	let self = this;
  	
    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je item wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(item);
      }
    });
  }
  
  openForm (data)
  {
	  let self = this;
	
	  const filterModal = this.modalService.open(InventoryFormComponent, {
      windowClass: 'modal-right',
      backdrop: 'static',
      size: 'lg'
	  });
	  
	  filterModal.componentInstance.id = data['id'];
	  filterModal.componentInstance.closeAfterSave = true;
	  filterModal.result.then((result) => {
		  self.refresh = Math.random(); 
	  })
	  .catch((error) => {

	  });
  }
}
