<section *ngIf="item"  [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header" *ngIf="item">
		<h5>
			<span *ngIf="!item['id']">Klant toevoegen</span>
			<span *ngIf="item.id">Klant wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body p-0">	 

		<ul class="nav nav-fill mb-4">
			<li class="nav-item">
				<span class="nav-link " (click)="changeTab('info')" [ngClass]="{'active' : tabs['info'] == true}">Algemeen</span>
			</li>
			<li class="nav-item">
				<span class="nav-link " (click)="changeTab('users')" [ngClass]="{'active' : tabs['users'] == true}">Contacten</span>
			</li>
		</ul>

		<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

		<div class="px-3" *ngIf="tabs['users'] == true">
			<div class="row">
				<div class="col-6 d-flex justify-content-start mb-0" *ngIf="item['id']">
					<div *ngIf="tabs['users'] == true">
						<button class="btn btn-sm btn-success" (click)="openForm({id: '', organisationId: item.id, role: 'CONTACT'})">Toevoegen</button>
					</div>		
				</div>
				<div class="col-6">
					<div *ngIf="tabs['users'] == true">
						<input type="text" placeholder="Zoeken ...." [(ngModel)]="keyword" class="form-control" style="width:100%;">
					</div>		
				</div>
			</div>
		</div>

		<div class="mb-5 px-3" *ngIf="tabs['info'] == true">
			<section>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">Actief:</label>
					</div>
					<div class="col-md-10 mt-2">
						<div class="form-check form-switch">
							<input type="checkbox" class="form-check-input" id="active" role="switch" name="active" [(ngModel)]="item.active">
							<label class="form-check-label" for="active">Actief / niet actief</label>
						</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">Naam:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="name" required [(ngModel)]="item.name" name="name">
						<div class="error" *ngIf="errors.name">{{errors.name}}</div>
					</div>
				</div>

				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">Adres:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="street" required [(ngModel)]="item.street" name="street">
						<div class="error" *ngIf="errors.street">{{errors.street}}</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">Postcode:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="zipcode" required [(ngModel)]="item.zipcode" name="zipcode">
						<div class="error" *ngIf="errors.zipcode">{{errors.zipcode}}</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">Plaats:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="city" required [(ngModel)]="item.city" name="city">
						<div class="error" *ngIf="errors.city">{{errors.city}}</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">E-mail:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="city" required [(ngModel)]="item.email" name="email">
						<div class="error" *ngIf="errors.email">{{errors.email}}</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">Telefoon:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="city" required [(ngModel)]="item.phone" name="phone">
						<div class="error" *ngIf="errors.phone">{{errors.phone}}</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">BTW nummer:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="vatNumber" required [(ngModel)]="item.vatNumber" name="vatNumber">
						<div class="error" *ngIf="errors.vatNumber">{{errors.vatNumber}}</div>
					</div>
				</div>
				<div class="form-group row mb-2">
					<div class="col-md-2">
						<label class="col-form-label">KVK nummer:</label>
					</div>
					<div class="col-md-10">
						<input type="text" *ngIf="item" class="form-control" id="cocNumber" required [(ngModel)]="item.cocNumber" name="cocNumber">
						<div class="error" *ngIf="errors.cocNumber">{{errors.cocNumber}}</div>
					</div>
				</div> 
			</section>
		</div>

		<section *ngIf="tabs['users'] == true" class=" p-3">
			<section class="table-content" *ngIf="item['id']">
			<table class="table table-striped">
				<thead class="thead-dark">
				<tr>
					<th width="10">#</th>
					<th scope="col">Naam</th>
					<th scope="col">E-mail</th>
					<th scope="col">Functie</th>
					<th scope="col">Telefoon</th>
					<th scope="col">Actief</th>
					<th scope="col"></th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let user of searchUsers()">
					<td>{{ users.indexOf(user) + 1 }}</td>
					<td>{{user.name}}</td>
					<td>{{user.email}}</td>
					<td>{{user.function}}</td>
					<td>{{user.phone}}</td>
					<td [innerHTML]="item['activeLabel']"></td>
					<td style="text-align: right;">
						<span style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;" (click)="openForm({id: user.id, role: 'CONTACT'})"><i class="bi bi-pencil"></i></span>
						<span style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;" class="delete-link" (click)="confirmDelete(user)"><i class="bi bi-trash"></i></span>
					</td>
				</tr>
				</tbody>
			</table>
			</section>
		</section>
	</div> 
	<div class="modal-footer" *ngIf="item">
		<button (click)="save()" class="btn btn-success">
			<span *ngIf="!item.id"><i class="bi bi-plus-circle-fill pe-2"></i> Toevoegen</span>
			<span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
		</button>
	</div>
</section>